import { IProzessDto } from "../../../api/backend-api-v7";
import { BluetoothDataFormat } from "../../../store/bluetooth/bluetooth-data-format";
import {
  ProzessEventOperationTypes,
  ProzessInputData,
} from "../../../store/prozess-events/prozess-events.actions";

export interface TransponderDto {
  transponder: string;
  metaData: BluetoothDataFormat | undefined;
  isProzessCreated: boolean;
  recordId?: string;
}

export interface OwnProps {
  prozess: IProzessDto;
  recordId: string;
  shouldValidate: boolean;
}

export interface ConnectedState {
  transponder: BluetoothDataFormat | undefined;
  prevRecordId: string;
  isManuallyEmpty: boolean;
  shouldCreateProzessEventForMultipleProzess: boolean;
  editedValue: { value: string; recordId: string }[] | undefined;
  isEditing: boolean;
}

export interface ConnectedDispatch {
  onChanged: (prozessInputData: ProzessInputData) => void;
  resetTransponderData: () => void;
  saveDeletedTransponder: (transponder: TransponderDto) => void;
  setShouldCreateProzessEventForMultipleProzess: (shouldCreate: boolean) => void;
  setCountOfMultipleProzessEvents: (count: number | undefined) => void;
}

export interface Props extends OwnProps, ConnectedState, ConnectedDispatch {}

export interface GruppenerfassungState {
  transponders: TransponderDto[];
  isError: boolean;
  isValid: boolean;
}

export interface GruppenerfassungAction {
  type: GruppenerfassungActionType;
  payload: {
    value: TransponderDto | undefined | any;
    isRequired?: boolean;
    shouldKeepValue?: boolean;
    status: ProzessEventOperationTypes;
    isValid?: boolean;
  };
}

export enum GruppenerfassungActionType {
  INIT,
  ADD_PROZESS_DATA,
  RESET,
  DELETE_PROZESS_DATA,
  CONFIRM_CREATE_PROZESS,
}
