import { Action } from "../action";

export enum CommonActionType {
  TOGGLE_DRAWER = "TOGGLE_DRAWER",
  IS_APP_UPDATE_AVAILABLE = "IS_APP_UPDATE_AVAILABLE",
  TOGGLE_EDIT_MODE = "TOGGLE_EDIT_MODE",
  HIGHLIGHT_RECORD = "HIGHLIGHT_RECORD",
  IS_MANUALLY_EMPTY = "IS_MANUALLY_EMPTY",
  SET_IS_DIALOG_OPEN = "SET_IS_DIALOG_OPEN",
  SHOULD_CREATE_PROZESS_EVENT_FOR_MULTIPLE_PROZESS = "SHOULD_CREATE_PROZESS_EVENT_FOR_MULTIPLE_PROZESS",
  SET_COUNT_OF_MULTIPLE_PROZESS_EVENTS = "SET_COUNT_OF_MULTIPLE_PROZESS_EVENTS",
  USE_DIFFERENTIAL_WEIGHTING = "USE_DIFFERENTIAL_WEIGHTING",
  FORCE_USER_TO_UPDATE = "FORCE_USER_TO_UPDATE",
  IS_APP_DEPRECATED = "IS_APP_DEPRECATED",
  TOGGLE_DATA_IMPORT_SNACKBAR = "TOGGLE_DATA_IMPORT_SNACKBAR",
  SET_SHOULD_OPEN_VALIDATION_OVERVIEW_DIALOG = "SET_SHOULD_OPEN_VALIDATION_OVERVIEW_DIALOG",
}

export const toggleDrawer = (drawerOpen: boolean): Action<boolean> => ({
  type: CommonActionType.TOGGLE_DRAWER,
  payload: drawerOpen,
});

export const toggleIsAppUpdateAvailable = (isAvailable: boolean): Action<boolean> => ({
  type: CommonActionType.IS_APP_UPDATE_AVAILABLE,
  payload: isAvailable,
});

export const toggleEditMode = (isActivated: boolean): Action<boolean> => ({
  type: CommonActionType.TOGGLE_EDIT_MODE,
  payload: isActivated,
});

export const highlightRecord = (shouldHighlight: boolean): Action<boolean> => ({
  type: CommonActionType.HIGHLIGHT_RECORD,
  payload: shouldHighlight,
});

export const setIsManuallyEmpty = (isManuallyEmpty: boolean): Action<boolean> => ({
  type: CommonActionType.IS_MANUALLY_EMPTY,
  payload: isManuallyEmpty,
});

export const setIsDialogOpen = (isDialogOpen: boolean): Action<boolean> => ({
  type: CommonActionType.SET_IS_DIALOG_OPEN,
  payload: isDialogOpen,
});

export const shouldCreateProzessEventForMultipleProzess = (shouldCreate: boolean): Action<boolean> => ({
  type: CommonActionType.SHOULD_CREATE_PROZESS_EVENT_FOR_MULTIPLE_PROZESS,
  payload: shouldCreate,
});

export const setCountOfMultipleProzessEvents = (count: number | undefined): Action<number | undefined> => ({
  type: CommonActionType.SET_COUNT_OF_MULTIPLE_PROZESS_EVENTS,
  payload: count,
});

export const activateDifferentialWeighting = (shouldUseDifferentialWeighting: boolean): Action<boolean> => ({
  type: CommonActionType.USE_DIFFERENTIAL_WEIGHTING,
  payload: shouldUseDifferentialWeighting,
});

export const forceUserToUpdate = (forceUpdate: boolean): Action<boolean> => ({
  type: CommonActionType.FORCE_USER_TO_UPDATE,
  payload: forceUpdate,
});

export const setIsAppDeprecated = (isDeprecated: boolean): Action<boolean> => ({
  type: CommonActionType.IS_APP_DEPRECATED,
  payload: isDeprecated,
});

export const toggleDataImportSnackbar = (isOpen: boolean): Action<boolean> => ({
  type: CommonActionType.TOGGLE_DATA_IMPORT_SNACKBAR,
  payload: isOpen,
});

export const setShouldOpenValidationOverviewDialog = (shouldOpen: boolean): Action<boolean> => ({
  type: CommonActionType.SET_SHOULD_OPEN_VALIDATION_OVERVIEW_DIALOG,
  payload: shouldOpen,
});
