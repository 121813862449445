import { ChangeEvent, FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import WarningModalWindow from "../../modal-windows/warning-dialog/warning-dialog.component";
import classNames from "classnames";
import useStyles from "./combo-box.styles";
import { useTranslation } from "react-i18next";
import { Props } from "./combo-box.types";
import { useComboBoxState } from "./combo-box.hooks";
import { ILocalFerkelDto } from "../../../db/database";
import { BluetoothDataFormat } from "../../../store/bluetooth/bluetooth-data-format";
import { IProzessDataDto, ProzessType } from "../../../api/backend-api-v7";
import { defineFerkelProperty } from "../../../utils/ferkel.utils";

const saveWhenRecordIsActive = (
  prevRecordId: string,
  recordId: string,
  save: (value: string | undefined, metaData?: BluetoothDataFormat) => void,
  value: string | undefined,
  metaData: BluetoothDataFormat | undefined,
  isProzessEdited: boolean | undefined,
  isEditing: boolean
) => {
  const isRecordActive = prevRecordId === recordId;

  if (isEditing) {
    if (isRecordActive && isProzessEdited) {
      save(value, metaData);
    }
  } else {
    if (isRecordActive) {
      save(value, metaData);
    }
  }
};

const ComboBoxComponent: FunctionComponent<Props> = props => {
  const {
    prozess,
    recordId,
    shouldValidate,
    onChanged,
    prevRecordId,
    editedValue,
    setIsValidationError,
    ferkel,
    funktionId,
    isManuallyEmpty,
    transponder,
    tierIdent,
    resetTransponderData,
    resetTierIdentData,
    manualInput,
    shouldCreateProzessEventForMultipleProzess,
    createFilteredBy,
    isEditing,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const ferkelIdentificator: keyof ILocalFerkelDto = useMemo(
    () => defineFerkelProperty(prozess.prozessType!),
    [prozess.prozessType]
  );

  const [optionsData, setOptionsData] = useState<string[]>([]);
  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const [shouldShowMissingDialog, setShouldShowMissingDialog] = useState(false);
  const [shouldShowAlreadyExistDialog, setShouldShowAlreadyExistDialog] = useState(false);

  const { init, save, onProzessDataChanged, reset, state, shouldEditProzessData, createFilteredByValue } =
    useComboBoxState(prozess, onChanged, isEditing, optionsData, createFilteredBy);

  const createProzessEventForMultipleProzess = useCallback(() => {
    // Fired if new prozess event for prozess which can create multiple prozess events was created in edit mode.
    if (shouldCreateProzessEventForMultipleProzess) {
      shouldEditProzessData(true);
    }
  }, [shouldCreateProzessEventForMultipleProzess, shouldEditProzessData]);

  const checkIsValueAlreadyUsed = useCallback(
    (value: string) => {
      if (prozess.prozessType === ProzessType.TIER_IDENT || prozess.prozessType === ProzessType.TRANSPONDER) {
        const selectedFerkel = ferkel.find(item => item[ferkelIdentificator]?.toString() === value);
        const ferkelAlreadyUsed =
          !!selectedFerkel && selectedFerkel.funktionenHistory!.find(item => item.funktionId === funktionId);
        return !!ferkelAlreadyUsed;
      }
    },
    [ferkel, ferkelIdentificator, funktionId, prozess.prozessType]
  );

  const checkIfValueExists = useCallback(
    (value: string) => !!ferkel.find(item => item[ferkelIdentificator]?.toString() === value),
    [ferkel, ferkelIdentificator]
  );

  const extractOptionsData = useCallback(() => {
    switch (prozess.prozessType) {
      case ProzessType.TIER_IDENT:
      case ProzessType.TRANSPONDER: {
        if (ferkel) {
          const data = ferkel.reduce((total: string[], value: ILocalFerkelDto) => {
            if (value[ferkelIdentificator]) {
              total.push(value[ferkelIdentificator]!.toString());
            }
            return total;
          }, []);
          const sortedData = data.sort((a, b) => a.localeCompare(b));
          setOptionsData(sortedData);
        }
        break;
      }
      default: {
        const prozessDataLabels = prozess.data!.reduce(
          (total: string[], value: IProzessDataDto) => [...total, value.label!],
          []
        );
        const sortedData = prozessDataLabels.sort((a, b) => a.localeCompare(b));
        setOptionsData(sortedData);
      }
    }
  }, [ferkel, ferkelIdentificator, prozess.data, prozess.prozessType]);

  const checkOnEditMode = useCallback(() => {
    if (isEditing && editedValue) {
      if (!optionsData.includes(editedValue.toString())) {
        setOptionsData(prev => [editedValue.toString(), ...prev]);
      }
      onProzessDataChanged(editedValue.toString());
      setSelectedValue(editedValue.toString());
      createFilteredByValue(editedValue.toString());
    } else {
      onProzessDataChanged("");
      setSelectedValue(null);
      shouldEditProzessData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedValue, isEditing]);

  const validateStateError = useCallback(() => {
    // Say Funktion component, that prozess has a validation error in edit mode.
    if (state.isError) {
      setIsValidationError(true);
    } else {
      setIsValidationError(false);
    }
  }, [setIsValidationError, state.isError]);

  const saveValueViaBluetooth = useCallback(() => {
    if (prozess.prozessType === ProzessType.TIER_IDENT || prozess.prozessType === ProzessType.TRANSPONDER) {
      if ((transponder || tierIdent) && !manualInput) {
        const bluetoothData = prozess.prozessType === ProzessType.TIER_IDENT ? tierIdent : transponder;

        if (bluetoothData) {
          const value = bluetoothData.value;

          resetTransponderData();
          resetTierIdentData();

          const selectedFerkel = checkIfValueExists(value);
          if (!selectedFerkel) {
            setShouldShowMissingDialog(true);
            return;
          }

          const isValueAlreadyUsed = checkIsValueAlreadyUsed(value);
          if (isValueAlreadyUsed && !prozess.shouldAllowMultipleUsage) {
            setShouldShowAlreadyExistDialog(true);
            return;
          }

          if (isEditing) {
            shouldEditProzessData(true);
          }

          onProzessDataChanged(value, bluetoothData);
          setSelectedValue(value);
        }
      }
    }
  }, [
    checkIfValueExists,
    checkIsValueAlreadyUsed,
    isEditing,
    manualInput,
    onProzessDataChanged,
    prozess.shouldAllowMultipleUsage,
    prozess.prozessType,
    resetTierIdentData,
    resetTransponderData,
    shouldEditProzessData,
    tierIdent,
    transponder,
  ]);

  const resetWhenRecordIsActive = useCallback(() => {
    reset();
    if (!prozess.shouldKeepValue) {
      setSelectedValue(null);
    }
    setShouldShowMissingDialog(false);
    setShouldShowAlreadyExistDialog(false);
  }, [prozess.shouldKeepValue, reset]);

  useEffect(() => {
    extractOptionsData();
  }, [extractOptionsData]);

  useEffect(() => {
    init();
    setSelectedValue(null);
  }, [init, isManuallyEmpty]);

  useEffect(() => {
    resetWhenRecordIsActive();
  }, [recordId, resetWhenRecordIsActive]);

  useEffect(() => {
    saveWhenRecordIsActive(
      prevRecordId,
      recordId,
      save,
      state.value,
      state.metaData,
      state.isProzessEdited,
      isEditing
    );
    // Should listen only state changes for creating a prozess event.
    // It should update perfomance and prevent strange errors.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    createProzessEventForMultipleProzess();
  }, [createProzessEventForMultipleProzess]);

  useEffect(() => {
    checkOnEditMode();
  }, [checkOnEditMode]);

  useEffect(() => {
    validateStateError();
  }, [validateStateError]);

  useEffect(() => {
    saveValueViaBluetooth();
  }, [saveValueViaBluetooth]);

  const closeMissingValueDialog = useCallback(() => {
    setShouldShowMissingDialog(false);
  }, []);

  const closeAlreadyExistDialog = useCallback(() => {
    setShouldShowAlreadyExistDialog(false);
  }, []);

  const onInputChange = useCallback(
    (event: any, manualValue: string, reason: string) => {
      // Fired when the input value changes.
      if (isEditing) {
        shouldEditProzessData(true);
        if (reason === "clear") {
          // Filtered prozesse should remove information too in edit mode.
          createFilteredByValue("");
        }
      }
      if (reason === "input" && !!event && optionsData.includes(event.target.value)) {
        // User input.
        onProzessDataChanged(event.target.value);
        setSelectedValue(event.target.value);
        return;
      }
      if (reason === "reset" && !!event && optionsData.includes(event.target.innerText)) {
        // Programmatic change.
        onProzessDataChanged(event.target.innerText);
        setSelectedValue(event.target.innerText);
        return;
      }
      if (reason === "reset" && !!event && event.target.innerText === "") {
        // Remove selected value and input value if this value is not correct.
        onProzessDataChanged("");
        setSelectedValue(null);
        return;
      }
      if (reason === "clear") {
        onProzessDataChanged("");
        setSelectedValue(null);
        return;
      }

      onProzessDataChanged(manualValue);
    },
    [createFilteredByValue, isEditing, onProzessDataChanged, optionsData, shouldEditProzessData]
  );

  const onChange = useCallback(
    (value: string | null) => {
      // Fired when you select a value from the proposal list.
      if (isEditing) {
        shouldEditProzessData(true);
      }
      setSelectedValue(value);
    },
    [isEditing, shouldEditProzessData]
  );

  const handleDisabledOption = (value: string) => {
    if (prozess.shouldAllowMultipleUsage) {
      return false;
    } else {
      return !!checkIsValueAlreadyUsed(value);
    }
  };

  return (
    <div style={{ gridArea: prozess.position }} className={classes.root} data-cy="ComboBoxComponent">
      <Autocomplete
        id={prozess.prozessType}
        options={optionsData}
        getOptionDisabled={option => handleDisabledOption(option)}
        value={selectedValue}
        inputValue={state.value}
        onInputChange={(event: ChangeEvent<{}>, newValue: string, reason: string) =>
          onInputChange(event, newValue, reason)
        }
        onChange={(event: ChangeEvent<{}>, value: string | null) => onChange(value)}
        fullWidth={true}
        classes={{
          endAdornment: classes.endAdornment,
          popupIndicator: classes.popupIndicator,
          clearIndicator: classes.clearIndicator,
        }}
        noOptionsText={t("COMMON.NO_MATCHES")}
        renderInput={params => (
          <TextField
            required={prozess.isRequired}
            label={prozess.label!}
            variant="outlined"
            type="string"
            className={classNames(classes.textField, state.isValid ? classes.validField : "")}
            {...params}
            InputLabelProps={{ shrink: true }}
            error={shouldValidate && state.isError}
          />
        )}
      />
      {shouldShowMissingDialog && (
        <WarningModalWindow
          open={shouldShowMissingDialog}
          acceptHandler={closeMissingValueDialog}
          title={`MODAL.WARNING.UNKNOWN[${prozess.prozessType!.toLowerCase()}].TITLE`}
        >
          {`MODAL.WARNING.UNKNOWN[${prozess.prozessType!.toLowerCase()}].TEXT`}
        </WarningModalWindow>
      )}
      {shouldShowAlreadyExistDialog && (
        <WarningModalWindow
          open={shouldShowAlreadyExistDialog}
          acceptHandler={closeAlreadyExistDialog}
          title={`MODAL.WARNING.EXIST[${prozess.prozessType!.toLowerCase()}].TITLE`}
        >
          {`MODAL.WARNING.EXIST[${prozess.prozessType!.toLowerCase()}].TEXT`}
        </WarningModalWindow>
      )}
    </div>
  );
};

export default ComboBoxComponent;
