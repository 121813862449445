import { BluetoothDataFormat } from "../../../store/bluetooth/bluetooth-data-format";
import { IProzessDto } from "../../../api/backend-api-v7";
import {
  ProzessEventOperationTypes,
  ProzessInputData,
} from "../../../store/prozess-events/prozess-events.actions";

export interface GewichtConfiguration {
  validMinValue?: number;
  validMaxValue?: number;
  optimalMinValue?: number;
  optimalMaxValue?: number;
  gewichtMaxDifference?: number;
  gewichtAverageCount?: number;
  skipNumberOfValidGewichtValues?: number;
}

export interface OwnProps {
  prozess: IProzessDto;
  recordId: string;
  manualInput: boolean;
  shouldValidate: boolean;
  setIsValidationError: (isError: boolean) => void;
  isWaageMode?: boolean;
  shouldCalculateDifference?: boolean;
}

export interface ConnectedState {
  stableGewicht: BluetoothDataFormat | undefined;
  lastReceivedGewicht: BluetoothDataFormat | undefined;
  prevRecordId: string;
  isManuallyEmpty: boolean;
  shouldUseManualGewichtValue: boolean;
  shouldCreateProzessEventForMultipleProzess: boolean;
  editedValue: string | undefined;
  isEditing: boolean;
}

export interface ConnectedDispatch {
  restartStableGewicht: (gewichtConfiguration: GewichtConfiguration) => void;
  onChanged: (prozessInputData: ProzessInputData) => void;
  sendTareData: (message: string) => void;
  stableGewichtCaptured: (stableGewicht: BluetoothDataFormat) => void;
  activateDifferentialWeighting: (shouldUseDifferentialWeighting: boolean) => void;
}

export interface GewichtProps extends OwnProps, ConnectedState, ConnectedDispatch {}

export interface GewichtState {
  gewicht: string;
  metaData?: BluetoothDataFormat;
  isError: boolean;
  isValid: boolean;
  isOptimal: boolean;
  isStable: boolean;
  isProzessEdited?: boolean;
}

export interface GewichtAction {
  type: GewichtActionType;
  payload: {
    value: string | undefined;
    metaData?: BluetoothDataFormat;
    isRequired?: boolean;
    status: ProzessEventOperationTypes;
    isValid?: boolean;
    isOptimal?: boolean;
    isStable?: boolean;
    isProzessEdited?: boolean;
  };
}

export enum GewichtActionType {
  INIT,
  PROZESS_DATA_CHANGED,
  RESET,
  PROZESS_DATA_EDITED,
}
