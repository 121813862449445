import { FunctionComponent, ChangeEvent, useState, useMemo, useCallback, useEffect, memo } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Props } from "./select-dialog.types";
import useStyles from "./select-dialog.styles";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import ConfirmationDialogComponent from "../confirmation-dialog/confirmation-dialog.component";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { IProzessDataDto } from "../../api/backend-api-v7";
import { SearchField } from "../search-field/search-field.component";
import { CHUNK_SIZE_TO_DISPLAY } from "../../constants";

const SelectDialogComponent: FunctionComponent<Props> = props => {
  const {
    prozess,
    fullScreen,
    isOpen,
    onClose,
    saveValues,
    transponder,
    shouldSaveBluetoothData,
    resetTransponderData,
    isManuallyEmpty,
    editedValue,
    setIsDialogUsed,
    recordId,
    nextValueToSelect,
    setNextValue,
    selectedValueFromGivenData,
    buchtIdToFilter,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<IProzessDataDto[]>([]);

  const [selectedValues, setSelectedValues] = useState<IProzessDataDto[]>([]);
  const [dataToDisplay, setDataToDisplay] = useState<IProzessDataDto[][] | IProzessDataDto[]>([]);
  const [chunkDataToDisplay, setChunkDataToDisplay] = useState<IProzessDataDto[][] | IProzessDataDto[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [unconfirmedSelectedData, setUnconfirmedSelectedData] = useState<IProzessDataDto | undefined>(
    undefined
  );

  /**
   * Determines whether the group order is necessary.
   * @return the {@link Boolean}.
   */
  const shouldGroupByOrder = useMemo(
    () => prozess.data?.some(item => item.additional && item.additional.groupOrder > 0),
    [prozess.data]
  );

  /**
   * Determines whether the order in group is necessary.
   * @return the {@link Boolean}.
   */
  const shouldSortByOrder = useMemo(
    () => prozess.data?.some(item => item.additional && item.additional.countOrder > 0),
    [prozess.data]
  );

  /**
   * SearchField onChange handler.
   */
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  /**
   * CloseIcon button and close dialog handler.
   */
  const handleCloseDialog = () => {
    setSearchTerm("");
    setSearchResults([]);
    setChunkDataToDisplay(dataToDisplay.slice(0, CHUNK_SIZE_TO_DISPLAY));
    setHasMore(true);
    onClose();
  };

  /**
   * Handles the user's selection from ConfirmationDialog.
   */
  const handleConfirmationDialog = (confirmed: boolean) => {
    if (confirmed) {
      checkSelection(unconfirmedSelectedData!);
    }
    setUnconfirmedSelectedData(undefined);
    onClose();
  };

  /**
   * Group ProzessDataDto as arrays with same groupOrder. GroupOrder is a backend configuration.
   * @return the {@link IProzessDataDto}[] groups as array.
   */
  const groupByGroupOrder = (data: IProzessDataDto[]): IProzessDataDto[][] => {
    const result = data.reduce((acc: { [key: number]: IProzessDataDto[] }, curr: IProzessDataDto) => {
      acc[curr.additional?.groupOrder] = [...(acc[curr.additional?.groupOrder] || []), curr];
      return acc;
    }, {});

    return Object.values(result);
  };

  /**
   * Sort group of ProzessDataDto by backend configuration or alphabet order.
   * * @return the {@link IProzessDataDto}[].
   */
  const sortProzessData = (prozzessData: IProzessDataDto[], shouldSortByOrder: boolean | undefined) => {
    if (shouldSortByOrder) {
      return prozzessData.sort((a, b) => {
        if (a.additional!.countOrder === b.additional!.countOrder) {
          return a.label!.localeCompare(b.label!, undefined, { numeric: true, sensitivity: "base" });
        }
        return a.additional!.countOrder > b.additional!.countOrder ? 1 : -1;
      });
    }

    return prozzessData.sort((a, b) =>
      a.label!.localeCompare(b.label!, undefined, { numeric: true, sensitivity: "base" })
    );
  };

  /**
   * Edge case. Prefilter data to display by BuchId.
   */
  const prefilterDataByBuchtInfo = useCallback(
    (data: IProzessDataDto[]) => {
      if (buchtIdToFilter) {
        // remove prozessData without buchten.
        const prozessDataWithBucht = data.filter(item => item.additional?.buchten.length);
        // remove prozessData without selected bucht.
        return prozessDataWithBucht.filter(item =>
          item.additional?.buchten.some((item: any) => item.buchtId === buchtIdToFilter)
        );
      } else {
        return data;
      }
    },
    [buchtIdToFilter]
  );

  /**
   * Prepares data to display before the dialog open and update it to show search result.
   */
  const prepareDataToDisplay = useCallback(() => {
    const dataToHandle = prefilterDataByBuchtInfo(searchTerm ? searchResults : prozess.data!);
    let modifiedData: IProzessDataDto[][] | IProzessDataDto[] = [];

    if (shouldGroupByOrder) {
      modifiedData = groupByGroupOrder(dataToHandle);
    }
    if (Array.isArray(modifiedData[0])) {
      modifiedData.forEach(group => sortProzessData(group as IProzessDataDto[], shouldSortByOrder));
    } else {
      modifiedData = sortProzessData(dataToHandle, shouldSortByOrder);
    }

    setDataToDisplay(modifiedData);
    setChunkDataToDisplay(modifiedData.slice(0, CHUNK_SIZE_TO_DISPLAY));
  }, [
    prefilterDataByBuchtInfo,
    prozess.data,
    searchResults,
    searchTerm,
    shouldGroupByOrder,
    shouldSortByOrder,
  ]);

  /**
   * Determines whether the value can be selected.
   * @return the {@link Boolean}.
   */
  const shouldValueBeSelected = useCallback(
    (data: IProzessDataDto) =>
      !!selectedValues.find(sv => (typeof sv === "object" ? sv.label === data.label : sv === data.id)),
    [selectedValues]
  );

  /**
   * Determines whether the value was already selected.
   * @return the {@link Boolean}.
   */
  const isValueAlreadySelected = useCallback(
    (value: IProzessDataDto) => {
      if (!value.additional) {
        return selectedValues.some(sv => sv.id === value.id);
      } else {
        return selectedValues.some(
          sv =>
            sv.id === value.id &&
            (sv.additional!.belegNr === value.additional!.belegNr ||
              sv.additional!.ammenObjectId === value.additional!.ammenObjectId)
        );
      }
    },
    [selectedValues]
  );

  /**
   * Sets the next value to select.
   * If the current value is the last value of a group then the next value will be undefined.
   */
  const setNextValueToSelect = useCallback(
    (group: IProzessDataDto[], currentValue: IProzessDataDto[]) => {
      const currentIndex = group.findIndex(el => el.id.toString() === currentValue[0].id.toString());

      if (currentIndex >= 0) {
        const nextValue = group.find((el, index) => index === currentIndex + 1);
        setNextValue(nextValue);
      } else {
        setNextValue(undefined);
      }
    },
    [setNextValue]
  );

  /**
   * Determines in which group the next value can be selected.
   * Only for single selection dialog, based on Prozess.ShouldPreselectNextValue configuration.
   */
  const defineNextValueToSelect = useCallback(() => {
    if (!prozess.isMultipleSelectionAllowed && prozess.shouldPreselectNextValue && selectedValues.length) {
      if (Array.isArray(dataToDisplay[0])) {
        // Find next value in group.
        const currentGroup = (dataToDisplay as IProzessDataDto[][]).find((group: IProzessDataDto[]) =>
          group.find(el => el.id.toString() === selectedValues[0].id.toString())
        );
        setNextValueToSelect(currentGroup!, selectedValues);
      } else {
        // Use all data.
        setNextValueToSelect(dataToDisplay as IProzessDataDto[], selectedValues);
      }
    } else {
      setNextValue(undefined);
    }
  }, [
    dataToDisplay,
    prozess.isMultipleSelectionAllowed,
    prozess.shouldPreselectNextValue,
    selectedValues,
    setNextValue,
    setNextValueToSelect,
  ]);

  /**
   * Applies selected values and closes dialog.
   * @param value {@link IProzessDataDto}[].
   */
  const applySelection = useCallback(
    (value: IProzessDataDto[]) => {
      saveValues(value);
      setSearchTerm("");
      setSearchResults([]);

      if (isOpen) {
        onClose();
      }
    },
    [isOpen, onClose, saveValues]
  );

  /**
   * Verifies selected value and determines the way to handle it (single or multiple selection).
   * @param selection {@link IProzessDataDto}
   */
  const checkSelection = useCallback(
    (selection: IProzessDataDto) => {
      let newSelection: IProzessDataDto[];

      if (isValueAlreadySelected(selection)) {
        // Remove already selected value.
        newSelection = selectedValues.filter(item => item.id !== selection.id);
      } else {
        if (prozess.isMultipleSelectionAllowed) {
          newSelection = [...selectedValues, selection];
        } else {
          newSelection = [selection];
        }
      }
      setSelectedValues(newSelection);

      if (!prozess.isMultipleSelectionAllowed) {
        applySelection(newSelection);
      }
    },
    [applySelection, isValueAlreadySelected, prozess.isMultipleSelectionAllowed, selectedValues]
  );

  /**
   * Called when the button from dialog content have been clicked.
   * @param data {@link IProzessDataDto}
   */
  const onSelectionChanged = useCallback(
    (data: IProzessDataDto) => {
      if (prozess.isAskingForChangesConfirmation) {
        setUnconfirmedSelectedData(data);
        onClose();
      } else {
        checkSelection(data);
      }
    },
    [checkSelection, onClose, prozess.isAskingForChangesConfirmation]
  );

  /**
   * Filters dialog content based on searchTerm from the SearchField.
   */
  const filterSearchedResults = useCallback(() => {
    if (searchTerm) {
      const filteredData = prozess.data!.filter(item =>
        item.label?.toLowerCase().includes(searchTerm.toLowerCase())
      );

      setSearchResults(filteredData);
    }
  }, [prozess.data, searchTerm]);

  const fetchNewChunk = useCallback(() => {
    if (chunkDataToDisplay.length >= dataToDisplay.length) {
      setHasMore(false);
      return;
    }

    setChunkDataToDisplay(prev =>
      (prev as IProzessDataDto[]).concat(
        (dataToDisplay as IProzessDataDto[]).slice(prev.length, prev.length + CHUNK_SIZE_TO_DISPLAY)
      )
    );
  }, [chunkDataToDisplay.length, dataToDisplay]);

  /**
   * Renders dialog content based on grouping and sorting configuration.
   */
  const renderContent = useCallback(() => {
    if (shouldGroupByOrder) {
      return (dataToDisplay as IProzessDataDto[][]).map((row, rowIndex: number) => (
        <div key={rowIndex}>
          <div className={classes.fullScreenGrid}>
            {row.map((item: IProzessDataDto, index: number) => (
              <Button
                variant="contained"
                size="large"
                key={index}
                className={classnames(classes.touchButton, {
                  [classes.activeButton]: shouldValueBeSelected(item),
                })}
                onClick={() => onSelectionChanged(item)}
                data-cy="select-with-dialog-button"
              >
                {item.label ?? ""}
              </Button>
            ))}
          </div>
          {rowIndex !== dataToDisplay.length - 1 && <Divider component="div" className={classes.divider} />}
        </div>
      ));
    }

    return (
      <InfiniteScroll
        dataLength={chunkDataToDisplay.length}
        hasMore={hasMore}
        next={fetchNewChunk}
        loader={null}
        height={500}
        initialScrollY={0}
      >
        <div
          className={classnames({
            [classes.grid]: !fullScreen,
            [classes.fullScreenGrid]: fullScreen && !shouldGroupByOrder,
          })}
        >
          {(chunkDataToDisplay as IProzessDataDto[]).map((data: IProzessDataDto, index: number) => (
            <Button
              variant="contained"
              size="large"
              key={index}
              className={classnames(classes.touchButton, {
                [classes.activeButton]: shouldValueBeSelected(data),
              })}
              onClick={() => onSelectionChanged(data)}
              data-cy="select-with-dialog-button"
            >
              {data.label ?? ""}
            </Button>
          ))}
        </div>
      </InfiniteScroll>
    );
  }, [
    shouldGroupByOrder,
    chunkDataToDisplay,
    hasMore,
    fetchNewChunk,
    classes.grid,
    classes.fullScreenGrid,
    classes.divider,
    classes.touchButton,
    classes.activeButton,
    fullScreen,
    dataToDisplay,
    shouldValueBeSelected,
    onSelectionChanged,
  ]);

  /**
   * Selects value automatically via Bluetooth data based on Transponder value.
   */
  const saveValueViaBluetooth = useCallback(() => {
    if (transponder && shouldSaveBluetoothData) {
      if (isOpen) {
        const scannedProzess = prozess.data!.find(
          item => item.additional?.transponder?.toString() === transponder.value
        );
        // Don't cancel selection if value already select.
        if (scannedProzess && !isValueAlreadySelected(scannedProzess)) {
          resetTransponderData();
          onSelectionChanged(scannedProzess);
        }
      }
    }
  }, [
    isOpen,
    isValueAlreadySelected,
    onSelectionChanged,
    prozess.data,
    resetTransponderData,
    shouldSaveBluetoothData,
    transponder,
  ]);

  useEffect(() => {
    if (!prozess.shouldKeepValue && !selectedValueFromGivenData) {
      setSelectedValues([]);
    }
  }, [prozess.shouldKeepValue, recordId, selectedValueFromGivenData]);

  useEffect(() => {
    if (prozess.shouldPreselectNextValue && nextValueToSelect) {
      setSelectedValues([nextValueToSelect]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordId]);

  useEffect(() => {
    if (selectedValueFromGivenData) {
      setSelectedValues([selectedValueFromGivenData]);
    }
  }, [selectedValueFromGivenData]);

  useEffect(() => {
    if (isOpen) {
      resetTransponderData();
    }
  }, [isOpen, resetTransponderData]);

  useEffect(() => {
    if (!isOpen) {
      defineNextValueToSelect();
    }
  }, [defineNextValueToSelect, isOpen]);

  useEffect(() => {
    setSelectedValues([]);
    setNextValue(undefined);
  }, [isManuallyEmpty, setNextValue]);

  useEffect(() => {
    setSelectedValues(editedValue ?? []);
  }, [editedValue, setSelectedValues]);

  useEffect(() => {
    prepareDataToDisplay();
  }, [prepareDataToDisplay]);

  useEffect(() => {
    saveValueViaBluetooth();
  }, [saveValueViaBluetooth]);

  useEffect(() => {
    filterSearchedResults();
  }, [filterSearchedResults]);

  useEffect(() => {
    setIsDialogUsed(isOpen);
  }, [isOpen, setIsDialogUsed]);

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleCloseDialog}
        aria-labelledby="simple-dialog-title"
        fullScreen={fullScreen}
        classes={{
          paperFullScreen: classes.paperFullScreen,
          paper: classes.paper,
        }}
      >
        <DialogTitle>
          {prozess.label!}
          <IconButton aria-label="Close" className={classes.closeButton} onClick={handleCloseDialog}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>{renderContent()}</DialogContent>
        <DialogActions className={classes.dialogActions}>
          <SearchField
            handleChange={handleChange}
            handleCloseClick={() => setSearchTerm("")}
            searchTerm={searchTerm}
            dataCy="search-field"
          />
          {prozess.isMultipleSelectionAllowed && (
            <Button
              variant="contained"
              size="large"
              color="secondary"
              className={classes.applyButton}
              onClick={() => applySelection(selectedValues)}
            >
              {t("COMMON.ÜBERNEHMEN")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
      {unconfirmedSelectedData && (
        <ConfirmationDialogComponent
          open={true}
          onClose={(confirmed: boolean) => handleConfirmationDialog(confirmed)}
          message={t('Sind Sie sicher, dass Sie das Feld "_PLACEHOLDER_" ändern wollen?').replace(
            "_PLACEHOLDER_",
            prozess.label!
          )}
        />
      )}
    </>
  );
};

export default memo(SelectDialogComponent);
