import GruppenerfassungComponent from "./gruppenerfassung.component";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "../../../store/app.state";
import { getTransponder } from "../../../store/bluetooth/bluetooth.selectors";
import { prozessDataChanged, ProzessInputData } from "../../../store/prozess-events/prozess-events.actions";
import { getCurrentRecordId } from "../../../store/prozess-events/prozess-events.selectors";
import { ConnectedState, ConnectedDispatch, TransponderDto, OwnProps } from "./gruppenerfassung.types";
import { resetTransponderData } from "../../../store/bluetooth/bluetooth.actions";
import {
  getIsManuallyEmpty,
  getShouldCreateProzessEventForMultipleProzess,
  isEditModeActivated,
} from "../../../store/common/common.selectors";
import { trySetTransponder } from "../../../store/transitory-prozess-data/transitory-prozess-data.actions";
import {
  setCountOfMultipleProzessEvents,
  shouldCreateProzessEventForMultipleProzess,
} from "../../../store/common/common.actions";
import { getEditedValueByWorkflowId } from "../../../store/captured-records/captured-records.selectors";

const mapStateToProps = (state: AppState, ownProps: OwnProps): ConnectedState => {
  const workflowId = ownProps.prozess.workflowId!;

  return {
    transponder: getTransponder(state),
    prevRecordId: getCurrentRecordId(state),
    isManuallyEmpty: getIsManuallyEmpty(state),
    shouldCreateProzessEventForMultipleProzess: getShouldCreateProzessEventForMultipleProzess(state),
    editedValue: getEditedValueByWorkflowId(workflowId)(state),
    isEditing: isEditModeActivated(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  onChanged: (prozessInputData: ProzessInputData) => dispatch(prozessDataChanged(prozessInputData)),
  resetTransponderData: () => dispatch(resetTransponderData()),
  saveDeletedTransponder: (transponder: TransponderDto) => dispatch(trySetTransponder(transponder)),
  setShouldCreateProzessEventForMultipleProzess: (shouldCreate: boolean) =>
    dispatch(shouldCreateProzessEventForMultipleProzess(shouldCreate)),
  setCountOfMultipleProzessEvents: (count: number | undefined) =>
    dispatch(setCountOfMultipleProzessEvents(count)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GruppenerfassungComponent);
