import { ComboBoxAction, ComboBoxActionType, ComboBoxState } from "./combo-box.types";

import logger from "../../../logger";

const logInfo = logger.info("combox-box.reducer");

export const initialState: ComboBoxState = {
  value: "",
  metaData: undefined,
  isError: false,
  isValid: false,
  isProzessEdited: undefined,
};

const isError = (isValid: boolean, isRequired?: boolean) => !!(!isValid && isRequired);

export const comboBoxReducer = (state: ComboBoxState, action: ComboBoxAction): ComboBoxState => {
  const {
    type,
    payload: { value, metaData, isRequired, shouldKeepValue, isValid, isProzessEdited },
  } = action;

  switch (type) {
    case ComboBoxActionType.INIT: {
      logInfo("COMBO_BOX INIT");
      return { ...initialState, isError: isError(!!isValid, isRequired) };
    }
    case ComboBoxActionType.PROZESS_DATA_CHANGED: {
      const newValue = value ? (value as string) : "";
      if (state.value === newValue && state.isError === isError(!!isValid, isRequired)) {
        return state;
      }

      return {
        ...state,
        value: newValue,
        metaData,
        isError: isError(!!isValid, isRequired),
        isValid: !!isValid,
      };
    }
    case ComboBoxActionType.RESET: {
      logInfo("COMBO_BOX RESET");
      const value = shouldKeepValue ? state.value : "";
      return { ...initialState, value, isError: shouldKeepValue ? false : isError(!!isValid, isRequired) };
    }
    case ComboBoxActionType.PROZESS_DATA_EDITED: {
      return { ...state, isProzessEdited };
    }
  }

  return state;
};
