import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "../../store/app.state";

import SelectDialogComponent from "./select-dialog.component";
import { ConnectedState, ConnectedDispatch } from "./select-dialog.types";

import { getTransponder } from "../../store/bluetooth/bluetooth.selectors";
import { getIsDialogOpen, getIsManuallyEmpty } from "../../store/common/common.selectors";

import { setIsDialogOpen } from "../../store/common/common.actions";
import { resetTransponderData } from "../../store/bluetooth/bluetooth.actions";

const mapStateToProps = (state: AppState): ConnectedState => ({
  transponder: getTransponder(state),
  shouldSaveBluetoothData: getIsDialogOpen(state),
  isManuallyEmpty: getIsManuallyEmpty(state),
});

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  setIsDialogUsed: (isDialogOpen: boolean) => dispatch(setIsDialogOpen(isDialogOpen)),
  resetTransponderData: () => dispatch(resetTransponderData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectDialogComponent);
