import { useCallback, useReducer } from "react";
import { IProzessDto } from "../../../api/backend-api-v7";
import {
  ProzessEventOperationTypes,
  ProzessInputData,
} from "../../../store/prozess-events/prozess-events.actions";
import { gruppenerfassungReducer, initialState } from "./gruppenerfassung.reducer";
import { GruppenerfassungActionType, TransponderDto } from "./gruppenerfassung.types";

const isTransponderValid = (transponder: string | undefined) => {
  if (!transponder) {
    return false;
  }
  return true;
};

export const useGruppenerfassungState = (
  prozess: IProzessDto,
  onChanged: (prozessInputData: ProzessInputData) => void,
  isEditing: boolean
) => {
  const [state, dispatch] = useReducer(gruppenerfassungReducer, initialState);
  const { isRequired, shouldKeepValue } = prozess;

  const init = useCallback(
    () =>
      dispatch({
        type: GruppenerfassungActionType.INIT,
        payload: {
          value: undefined,
          isRequired,
          shouldKeepValue,
          status: ProzessEventOperationTypes.DELETE,
        },
      }),
    [isRequired, shouldKeepValue]
  );

  const onProzessDataChanged = useCallback(
    (value: TransponderDto | TransponderDto[] | undefined) => {
      dispatch({
        type: GruppenerfassungActionType.ADD_PROZESS_DATA,
        payload: {
          value,
          isRequired,
          isValid: !Array.isArray(value) ? !!value && isTransponderValid(value.transponder) : true,
          shouldKeepValue,
          status: ProzessEventOperationTypes.CREATE,
        },
      });
    },
    [isRequired, shouldKeepValue]
  );

  const reset = useCallback(() => {
    dispatch({
      type: GruppenerfassungActionType.RESET,
      payload: {
        value: undefined,
        isRequired,
        shouldKeepValue,
        status: ProzessEventOperationTypes.DELETE,
      },
    });

    onChanged({
      workflowId: prozess.workflowId,
      eventType: isEditing ? prozess.changeEventType : prozess.eventType,
      eventCategory: prozess.eventCategory,
      data: undefined,
      metaData: undefined,
      additionalData: undefined,
      operation: ProzessEventOperationTypes.DELETE,
      isValid: prozess.isRequired ? isTransponderValid(undefined) : true,
    } as ProzessInputData);
  }, [
    isEditing,
    isRequired,
    onChanged,
    prozess.changeEventType,
    prozess.eventCategory,
    prozess.eventType,
    prozess.workflowId,
    prozess.isRequired,
    shouldKeepValue,
  ]);

  const save = useCallback(
    (value: TransponderDto) => {
      dispatch({
        type: GruppenerfassungActionType.CONFIRM_CREATE_PROZESS,
        payload: {
          value,
          isRequired,
          isValid: isTransponderValid(value.transponder),
          shouldKeepValue,
          status: ProzessEventOperationTypes.CREATE,
        },
      });

      onChanged({
        workflowId: prozess.workflowId,
        eventType: isEditing ? prozess.changeEventType : prozess.eventType,
        eventCategory: prozess.eventCategory,
        data: Number(value.transponder),
        metaData: value.metaData,
        additionalData: undefined,
        operation: ProzessEventOperationTypes.CREATE,
        isValid: prozess.isRequired ? isTransponderValid(value.transponder) : true,
        prozessType: prozess.prozessType,
      } as ProzessInputData);
    },
    [
      isRequired,
      shouldKeepValue,
      onChanged,
      prozess.workflowId,
      prozess.changeEventType,
      prozess.eventType,
      prozess.eventCategory,
      prozess.isRequired,
      prozess.prozessType,
      isEditing,
    ]
  );

  const deleteProzessData = useCallback(
    (value: TransponderDto) => {
      dispatch({
        type: GruppenerfassungActionType.DELETE_PROZESS_DATA,
        payload: {
          value,
          isRequired,
          isValid: isTransponderValid(value.transponder),
          shouldKeepValue,
          status: ProzessEventOperationTypes.DELETE,
        },
      });

      // TransponderDto has recordId in edit mode. It doesn't have a local prozess event. Don't try to delete it locally.
      if (!value.recordId) {
        onChanged({
          workflowId: prozess.workflowId,
          eventType: prozess.eventType,
          eventCategory: prozess.eventCategory,
          data: Number(value.transponder),
          metaData: value.metaData,
          additionalData: undefined,
          operation: ProzessEventOperationTypes.DELETE,
          isValid: prozess.isRequired ? isTransponderValid(value.transponder) : true,
        } as ProzessInputData);
      }
    },
    [
      isRequired,
      onChanged,
      prozess.eventType,
      prozess.eventCategory,
      prozess.workflowId,
      prozess.isRequired,
      shouldKeepValue,
    ]
  );

  return { init, onProzessDataChanged, reset, save, state, deleteProzessData };
};
