import { Action } from "../action";
import {
  EventCategory,
  ProzessType,
  IProzessEventDto,
  IProzessEventsValidationResultDto,
} from "../../api/backend-api-v7";
import { IProzessEventsWithAdditionalData } from "../../pages/funktion/funktion.types";

export enum ProzessEventsActionType {
  SAVE_PROZESS_EVENTS = "SAVE_PROZESS_EVENTS",
  SAVE_PROZESS_EVENTS_SUCCESS = "SAVE_PROZESS_EVENTS_SUCCESS",
  SAVE_PROZESS_EVENTS_FAILURE = "SAVE_PROZESS_EVENTS_FAILURE",
  UPDATE_PROZESS_EVENTS = "UPDATE_PROZESS_EVENTS",
  APPLY_PROZESS_EVENTS_DATA = "APPLY_PROZESS_EVENTS_DATA",
  CREATE_NEW_RECORD = "CREATE_NEW_RECORD",
  CREATE_PROZESS_EVENT = "CREATE_PROZESS_EVENT",
  PROZESS_DATA_CHANGED = "PROZESS_DATA_CHANGED",
  SET_PROZESS_EVENT = "SET_PROZESS_EVENT",
  SET_MULTIPLE_PROZESS_EVENT = "SET_MULTIPLE_PROZESS_EVENT",
  PROZESS_EVENTS_NOT_VALID = "PROZESS_EVENTS_NOT_VALID",
  PROZESS_EVENTS_ARE_VALID = "PROZESS_EVENTS_ARE_VALID",
  DELETE_PROZESS_EVENT = "DELETE_PROZESS_EVENT",
  DELETE_MULTIPLE_PROZESS_EVENT = "DELETE_MULTIPLE_PROZESS_EVENT",
  CLEAR_PROZESS_EVENTS = "CLEAR_PROZESS_EVENTS",
  SET_GIVEN_DATA = "SET_GIVEN_DATA",
  GIVEN_DATA_APPLIED = "GIVEN_DATA_APPLIED",
  SET_FILTERED_BY = "SET_FILTERED_BY",
  DELETE_FILTERED_BY = "DELETE_FILTERED_BY",
  SET_COMPARISON_DATA = "SET_COMPARISON_DATA",
  DELETE_COMPARISON_DATA = "DELETE_COMPARISON_DATA",
  UPDATE_COMPARISON_DATA = "UPDATE_COMPARISON_DATA",
  CREATE_FILTERED_BY = "CREATE_FILTERED_BY",
  START_SYNCHRONOUS_PROCESSING = "START_SYNCHRONOUS_PROCESSING",
  FINISH_SYNCHRONOUS_PROCESSING = "FINISH_SYNCHRONOUS_PROCESSING",
}

export const saveProzessEvents = (): Action<null> => ({
  type: ProzessEventsActionType.SAVE_PROZESS_EVENTS,
  payload: null,
});

export const saveProzessEventsSuccess = (
  prozessEventsInfo: ProzessEventsInfo
): Action<ProzessEventsInfo> => ({
  type: ProzessEventsActionType.SAVE_PROZESS_EVENTS_SUCCESS,
  payload: prozessEventsInfo,
});

export const updateProzessEvents = (
  prozessEvents: (IProzessEventsWithAdditionalData | IProzessEventsWithAdditionalData[])[]
): Action<(IProzessEventsWithAdditionalData | IProzessEventsWithAdditionalData[])[]> => ({
  type: ProzessEventsActionType.UPDATE_PROZESS_EVENTS,
  payload: prozessEvents,
});

export const saveProzessEventsFailure = (
  prozessEventsInfo: ProzessEventsInfo
): Action<ProzessEventsInfo> => ({
  type: ProzessEventsActionType.SAVE_PROZESS_EVENTS_FAILURE,
  payload: prozessEventsInfo,
});

export const applyProzessEventsData = (prozessEvents: IProzessEventDto[]): Action<IProzessEventDto[]> => ({
  type: ProzessEventsActionType.APPLY_PROZESS_EVENTS_DATA,
  payload: prozessEvents,
});

export const createNewRecord = (): Action<null> => ({
  type: ProzessEventsActionType.CREATE_NEW_RECORD,
  payload: null,
});

export const prozessDataChanged = (prozessInputData: ProzessInputData): Action<ProzessInputData> => ({
  type: ProzessEventsActionType.PROZESS_DATA_CHANGED,
  payload: prozessInputData,
});

export const createProzessEvent = (prozessInputData: ProzessInputData): Action<ProzessInputData> => ({
  type: ProzessEventsActionType.CREATE_PROZESS_EVENT,
  payload: prozessInputData,
});

export const setProzessEvent = (prozessEvent: IProzessEventDto): Action<IProzessEventDto> => ({
  type: ProzessEventsActionType.SET_PROZESS_EVENT,
  payload: prozessEvent,
});

export const setMultipleProzessEvent = (prozessEvent: IProzessEventDto): Action<IProzessEventDto> => ({
  type: ProzessEventsActionType.SET_MULTIPLE_PROZESS_EVENT,
  payload: prozessEvent,
});

export const deleteProzessEvent = (workflowId: number): Action<number> => ({
  type: ProzessEventsActionType.DELETE_PROZESS_EVENT,
  payload: workflowId,
});

export const deleteMultipleProzessEvent = (prozessInputData: ProzessInputData): Action<ProzessInputData> => ({
  type: ProzessEventsActionType.DELETE_MULTIPLE_PROZESS_EVENT,
  payload: prozessInputData,
});

export const clearProzessEvents = () => ({
  type: ProzessEventsActionType.CLEAR_PROZESS_EVENTS,
  payload: null,
});

export const prozessEventsNotValid = () => ({
  type: ProzessEventsActionType.PROZESS_EVENTS_NOT_VALID,
  payload: null,
});

export const prozessEventsAreValid = () => ({
  type: ProzessEventsActionType.PROZESS_EVENTS_ARE_VALID,
  payload: null,
});

export const setGivenData = (
  prozessInputData: Partial<ProzessInputData>
): Action<Partial<ProzessInputData>> => ({
  type: ProzessEventsActionType.SET_GIVEN_DATA,
  payload: prozessInputData,
});

export const givenDataApplied = (workflowId: number): Action<number> => ({
  type: ProzessEventsActionType.GIVEN_DATA_APPLIED,
  payload: workflowId,
});

export const setFilteredBy = (prozessInputData: ProzessInputData): Action<ProzessInputData> => ({
  type: ProzessEventsActionType.SET_FILTERED_BY,
  payload: prozessInputData,
});

export const deleteFilteredBy = (workflowId: number): Action<number> => ({
  type: ProzessEventsActionType.DELETE_FILTERED_BY,
  payload: workflowId,
});

export const setComparisonData = (prozessInputData: ProzessInputData): Action<ProzessInputData> => ({
  type: ProzessEventsActionType.SET_COMPARISON_DATA,
  payload: prozessInputData,
});

export const deleteComparisonData = (workflowId: number): Action<number> => ({
  type: ProzessEventsActionType.DELETE_COMPARISON_DATA,
  payload: workflowId,
});

export const updateComparisonData = (): Action<null> => ({
  type: ProzessEventsActionType.UPDATE_COMPARISON_DATA,
  payload: null,
});

export const createFilteredBy = (prozessInputData: ProzessInputData): Action<ProzessInputData> => ({
  type: ProzessEventsActionType.CREATE_FILTERED_BY,
  payload: prozessInputData,
});

export const startSynchronousProcessing = (): Action<null> => ({
  type: ProzessEventsActionType.START_SYNCHRONOUS_PROCESSING,
  payload: null,
});

export const finishSynchronousProcessing = (): Action<null> => ({
  type: ProzessEventsActionType.FINISH_SYNCHRONOUS_PROCESSING,
  payload: null,
});

export interface ProzessInputData {
  sourceWorkflowId: number;
  workflowId: number;
  eventType: string;
  eventCategory: EventCategory | undefined;
  data: any;
  label: string | undefined;
  metaData: any;
  additionalData: any | undefined;
  operation: ProzessEventOperationTypes;
  isValid: boolean;
  prozessType: ProzessType;
}

export interface ProzessEventsInfo {
  prozessEvents: (IProzessEventsWithAdditionalData | IProzessEventsWithAdditionalData[])[];
  validationErrors: IProzessEventsValidationResultDto | undefined;
}

export enum ProzessEventOperationTypes {
  CREATE = "CREATE",
  DELETE = "DELETE",
  EDIT = "EDIT",
}
