import Dexie, { IndexableType } from "dexie";
import { exportDB } from "dexie-export-import";
import {
  IDeleteProzessEventsByRecordRequestDto,
  ICapturedRecordsGroupDto,
  IBuchtDto,
  IBuchtFunktionHistoryDto,
  IBuchtFutterDto,
  IBuchtKommentarDto,
  IProzessEventsValidationResultDto,
  ProcessingState,
  IPlanFerkelDto,
  IFerkelDto,
  IFerkelFunktionHistoryDto,
  ISauDto,
  ISauFunktionHistoryDto,
  ISauLifeStageDto,
  ISauBuchtDto,
  ISauLifeStageDescriptorDto,
} from "../api/backend-api-v7";

import FerkelService from "../store/ferkel/ferkel.utils";
import BuchtService from "../store/buchten/buchten.utils";
import SauService from "../store/sauen/sauen.utils";
import logger from "../logger";
import { IProzessEventsWithAdditionalData } from "../pages/funktion/funktion.types";
import { isExpirationDateGone } from "../utils/datetime.utils";

const logTrace = logger.info("database");

export interface ISyncedProzessEvent extends IProzessEventsWithAdditionalData {
  synced: number;
}

export interface ISyncedCapturedRecord extends ICapturedRecordsGroupDto {
  synced: number;
  recordId?: string;
  validationErrors: IProzessEventsValidationResultDto | undefined;
  previousData?: ISyncedCapturedRecord;
  dependsOnRecordGroupId?: string;
}

export interface ILocalFerkelFunktionHistoryDto extends IFerkelFunktionHistoryDto {
  previousData?: ILocalFerkelDto;
}

export interface ILocalFerkelDto
  extends Omit<IFerkelDto, "funktionenHistory" | "genetischeSauDescriptor" | "buchtsauDescriptor"> {
  funktionenHistory?: ILocalFerkelFunktionHistoryDto[] | undefined;
  genetischeSauDescriptor: ISauLifeStageDescriptorDto;
  buchtsauDescriptor: ISauLifeStageDescriptorDto;
  plan?: IPlanFerkelDto | undefined;
}

export interface ILocalSauFunktionHistoryDto extends ISauFunktionHistoryDto {
  previousData?: ILocalSauLifeStageDto;
}

export interface ILocalSauLifeStageDto extends Omit<ISauLifeStageDto, "funktionenHistory" | "buchten"> {
  funktionenHistory?: ILocalSauFunktionHistoryDto[] | undefined;
  buchten?: ISauBuchtDto[] | undefined;
}

export interface ILocalSauDto extends Omit<ISauDto, "lifeStages"> {
  lifeStages: ILocalSauLifeStageDto[];
}

export interface ILocalBuchtFunktionHistoryDto
  extends Omit<IBuchtFunktionHistoryDto, "futter" | "kommentare"> {
  previousData?: ILocalBuchtDto;
  futter?: IBuchtFutterDto;
  kommentare?: IBuchtKommentarDto[];
}

export interface ILocalBuchtDto extends Omit<IBuchtDto, "funktionenHistory"> {
  funktionenHistory?: ILocalBuchtFunktionHistoryDto[] | undefined;
}

export interface IValidationInfo {
  id: string;
  createdAtUtc: Date;
  info: string;
}

export class AppDatabase extends Dexie {
  // Declare implicit table properties.
  // (just to inform Typescript. Instanciated by Dexie in stores() method)
  prozessEvents: Dexie.Table<ISyncedProzessEvent, string>; // string = type of the primkey
  capturedRecords: Dexie.Table<ISyncedCapturedRecord, string>; // string = type of the primkey
  ferkel: Dexie.Table<ILocalFerkelDto, string>; // string = type of the primkey
  sauen: Dexie.Table<ILocalSauDto, string>;
  buchten: Dexie.Table<ILocalBuchtDto, string>;
  planFerkel: Dexie.Table<IPlanFerkelDto, number>;
  validationLogs: Dexie.Table<IValidationInfo, string>;

  constructor() {
    super("PigMonitorDatabase");
    this.version(2).stores({
      prozessEvents: "&transactionId, recordId, created, synced",
      capturedRecords: "&recordsGroupId, recordId, funktionId, timestamp, synced",
      ferkel:
        "&creationRecordId, tier_ident, transponder, genetischesau, buchtsau, geschlecht, betriebId, createdAtUtc, *funktionenHistory",
      sauen:
        "[tierSysId+belegNr], tierSysId, sauNrBetrieb, abferkeldatum, absetzdatum, mumien, *funktionenHistory, *buchten",
    });
    this.version(3).upgrade(tr => {
      tr.table("capturedRecords").clear();
    });
    this.version(4).stores({
      buchten: "&id, *funktionenHistory",
    });
    this.version(5).stores({
      prozessEvents: "&transactionId, recordId, recordsGroupId, created, synced",
    });
    this.version(6)
      .stores({
        ferkel:
          "&creationRecordId, tierIdent, transponder, genetischeSauTierSysId, buchtsauTierSysId, geschlecht, *funktionenHistory",
        prozessEvents: "&transactionId, recordId, recordsGroupId, createdAtUtc, synced",
      })
      .upgrade(tx =>
        tx
          .table("prozessEvents")
          .toCollection()
          .modify(prozessEvent => {
            if (prozessEvent.created) {
              prozessEvent.createdAtUtc = prozessEvent.created;
              delete prozessEvent.created;
            }
          })
      );
    this.version(7).stores({
      planFerkel: "&transponder, buchtId, geschlecht",
    });
    this.version(8).stores({
      sauen: null,
      ferkel: "&creationRecordId, tierIdent, transponder, geschlecht, *funktionenHistory",
    });
    this.version(9).stores({
      sauen: "&tierSysId, *lifeStages",
      capturedRecords: "&recordsGroupId, recordId, funktionId, timestamp, synced, dependsOnRecordGroupId",
    });
    this.version(10).stores({
      validationLogs: "&id, createdAtUtc, info",
    });

    // The following line is needed if your typescript
    // is compiled using babel instead of tsc:
    this.prozessEvents = this.table("prozessEvents");
    this.capturedRecords = this.table("capturedRecords");
    this.ferkel = this.table("ferkel");
    this.sauen = this.table("sauen");
    this.buchten = this.table("buchten");
    this.planFerkel = this.table("planFerkel");
    this.validationLogs = this.table("validationLogs");
  }

  addValidationLogs = async (info: IValidationInfo[]) => {
    await this.validationLogs.bulkAdd(info);
  };

  exportDB = async () =>
    await exportDB(db, {
      filter: (table: string) => table === "prozessEvents" || table === "validationLogs",
    });

  clearData = async (ids: string[]) => {
    await this.prozessEvents.clear();
    await this.capturedRecords.bulkDelete(ids);
  };

  addProzessEvents = async (prozessEvents: IProzessEventsWithAdditionalData[]) => {
    const newProzessEvents = prozessEvents.map(pe => ({
      ...pe,
      synced: 0,
    }));
    await this.prozessEvents.bulkAdd(newProzessEvents);
  };

  getUnsyncedProzessEvents = async (): Promise<IProzessEventsWithAdditionalData[]> =>
    await this.getProzessEvents(false);

  removeSyncedProzessEvents = async () => {
    const syncedProzessEvents = await this.prozessEvents
      .where("synced")
      .equals(1)
      .and(item => item.expirationDate === null) // Remove only synced PE without ExpirationDate.
      .toArray();

    await this.prozessEvents.bulkDelete(syncedProzessEvents.map(pe => pe.transactionId));
  };

  deleteHandledProzessEvents = async () => {
    const syncedProzessEvents = await this.prozessEvents
      .where("synced")
      .equals(1)
      .and(item => isExpirationDateGone(item.expirationDate))
      .toArray();

    await this.prozessEvents.bulkDelete(syncedProzessEvents.map(pe => pe.transactionId));
  };

  deleteProzessEvents = async (recordsGroupId: string) => {
    const prozessEvents = await this.prozessEvents.where("recordsGroupId").equals(recordsGroupId).toArray();

    await this.prozessEvents.bulkDelete(prozessEvents.map(pe => pe.transactionId));
  };

  markSyncedProzessEvents = async (prozessEvents: IProzessEventsWithAdditionalData[]) => {
    const updatedProzessEvents = prozessEvents.map(pe => ({
      ...pe,
      synced: 1,
    }));
    await this.prozessEvents.bulkPut(updatedProzessEvents);
  };

  markUnsyncedProzessEventsAsDeleted = async (recordsGroupId: string) => {
    const prozessEvents = await this.prozessEvents
      .where("recordsGroupId")
      .equals(recordsGroupId)
      .and(item => item.synced === 0)
      .toArray();

    const updatedProzessEvents = prozessEvents.map(pe => ({
      ...pe,
      processingState: ProcessingState.Deleted,
    }));

    await this.prozessEvents.bulkPut(updatedProzessEvents);
  };

  getProzessEvents = async (isSynced: boolean) => {
    const prozessEvents = await this.prozessEvents
      .where("synced")
      .equals(+isSynced)
      .toArray();

    return prozessEvents.map(pe => {
      const { synced, ...rest } = pe;
      return rest;
    });
  };

  getProzessEventsToRestoreDb = async () =>
    await this.prozessEvents.filter(pe => pe.processingState !== ProcessingState.Deleted).toArray();

  getCountOfUnsyncedProzessEvents = async () => {
    const prozessEvents = await this.getUnsyncedProzessEvents();

    return prozessEvents.filter(pe => pe.processingState !== ProcessingState.Deleted).length;
  };

  addFerkel = async (ferkel: ILocalFerkelDto[]) => {
    await this.ferkel.bulkAdd(ferkel);
  };

  updateFerkel = async (ferkel: ILocalFerkelDto[]) => {
    await this.ferkel.bulkPut(ferkel);
  };

  deleteAllFerkel = async () => {
    await this.ferkel.clear();
  };

  getFerkel = async () => await this.ferkel.toArray();

  getFerkelByQuery = async (query: string, value: IndexableType) => {
    logTrace("getFerkelByQuery", query, value);
    return await this.ferkel.where(query).equals(value).toArray();
  };

  /**
   * Get existing Ferkel from DB. The Ferkel can be defined only by Transponder and Tier Ident.
   * @param transponder the {@link IndexableType} or {@link undefined}.
   * @param tierident the {@link IndexableType} or {@link undefined}.
   * @return In case Ferkel exists the {@link ILocalFerkelDto} array is returned.
   */
  getFerkelToUpdate = async (
    transponder: IndexableType | undefined,
    tierident: IndexableType | undefined
  ) => {
    const TRANSPONDER = "transponder";
    const TIER_IDENT = "tierIdent";

    return await this.ferkel
      .where(TRANSPONDER)
      .equals(transponder ?? "")
      .or(TIER_IDENT)
      .equals(tierident ?? "")
      .toArray();
  };

  deleteFerkelHistory = async (data: IDeleteProzessEventsByRecordRequestDto) => {
    const { recordId, funktionId } = data;
    const ferkelData: ILocalFerkelDto[] = [];

    // Find existing ferkel in the local db.
    await this.ferkel.each(ferkel =>
      ferkel.funktionenHistory?.find(
        history =>
          history.funktionId === funktionId && history.recordId === recordId && ferkelData.push(ferkel)
      )
    );

    if (!!ferkelData && ferkelData.length > 0) {
      // Find history that should be delete.
      const historyForDeleting = ferkelData[0].funktionenHistory?.find(
        history => history.funktionId === funktionId && history.recordId === recordId
      );

      if (historyForDeleting?.previousData) {
        // Return previous info about ferkel if it exist.
        return await this.ferkel.bulkPut([historyForDeleting?.previousData as ILocalFerkelDto]);
      } else {
        // Remove history for current funktion and check if ferkel was used in another funktion.
        const filteredHistory = ferkelData[0].funktionenHistory?.filter(
          history => history.funktionId !== funktionId && history.recordId !== recordId
        );

        const updatedFerkel = {
          ...ferkelData[0],
          funktionenHistory: filteredHistory,
        };

        // If ferkel have no funktionenHistory, the ferkel should be delete.
        if (!updatedFerkel.funktionenHistory?.length) {
          return await this.ferkel.bulkDelete([updatedFerkel.creationRecordId!]);
        } else {
          return await this.ferkel.bulkPut([updatedFerkel]);
        }
      }
    }
  };

  updateFerkelDataEditMode = async (
    updatedFerkel: ILocalFerkelDto | undefined,
    prozessEvents: IProzessEventsWithAdditionalData[],
    funktionIdToModifyRecord: number | undefined
  ) => {
    const { funktionId, recordId, recordsGroupId } = prozessEvents[0];
    let existingFerkelFromDb: ILocalFerkelDto[] = [];

    // Find all existing ferkel in the local db for specific recordId.
    await this.ferkel.each(item =>
      item.funktionenHistory?.forEach(
        history =>
          history.funktionId === funktionId &&
          history.recordId === recordId &&
          existingFerkelFromDb.push(item)
      )
    );

    // In case simple changing Ferkel info in edit mode.
    // Current Ferkel didn't change.
    if (!updatedFerkel && existingFerkelFromDb.length === 1) {
      const updatedEntity = FerkelService.modifyFerkelEditMode(existingFerkelFromDb[0], prozessEvents);
      return await this.ferkel.bulkPut([updatedEntity]);
    }

    // In case current Ferkel was cahnged in edit mode.
    if (updatedFerkel && existingFerkelFromDb.length === 1) {
      const previousFerkelVersion = existingFerkelFromDb[0].funktionenHistory!.find(
        history => history.funktionId === funktionId && history.recordId === recordId
      )?.previousData as ILocalFerkelDto;

      if (funktionIdToModifyRecord) {
        this.checkModifiedRecords(
          funktionIdToModifyRecord,
          existingFerkelFromDb[0].transponder!,
          updatedFerkel.transponder!,
          recordsGroupId!
        );
        return await this.ferkel.bulkPut([updatedFerkel, previousFerkelVersion]);
      }

      const updatedEntity = FerkelService.modifyFerkel(updatedFerkel, prozessEvents);
      return await this.ferkel.bulkPut([updatedEntity, previousFerkelVersion]);
    }
  };

  addSauen = async (sauen: ILocalSauDto[]) => await this.sauen.bulkAdd(sauen);

  updateSauen = async (sauen: ILocalSauDto[]) => await this.sauen.bulkPut(sauen);

  deleteAllSauen = async () => {
    await this.sauen.clear();
  };

  getSauen = async () => await this.sauen.toArray();

  getSauenByQuery = async (query: string, value: IndexableType) =>
    await this.sauen.where(query).equals(value).toArray();

  updateSauenData = async (
    newSau: ILocalSauDto | undefined,
    prozessEvents: IProzessEventsWithAdditionalData[],
    identificator: ISauLifeStageDescriptorDto | undefined
  ) => {
    const { recordId, funktionId } = prozessEvents[0];
    let existingSauEditMode: ILocalSauDto[] = [];
    let currentStage: ILocalSauLifeStageDto[] = [];

    // Find all existing sau in the local db for specific recordId.
    await this.sauen.each(sau =>
      sau.lifeStages.forEach(stage =>
        stage.funktionenHistory?.forEach(
          history =>
            history.funktionId === funktionId &&
            history.recordId === recordId &&
            existingSauEditMode.push(sau) &&
            currentStage.push(stage)
        )
      )
    );

    // In case simple changing Sau info in edit mode.
    // Current Sau didn't change.
    if (!newSau && existingSauEditMode.length && currentStage.length) {
      const updatedSau = SauService.modifySauEditMode(existingSauEditMode[0], currentStage[0], prozessEvents);
      return await this.sauen.bulkPut([updatedSau]);
    }

    // In case current Sau was cahnged in edit mode.
    if (newSau && existingSauEditMode.length === 1 && currentStage.length) {
      const previousLifeStageVersion = currentStage[0].funktionenHistory!.find(
        history => history.funktionId === funktionId && history.recordId === recordId
      )?.previousData;
      const previousSauVersion = SauService.applyUpdatedLifeStage(
        existingSauEditMode[0],
        previousLifeStageVersion!.belegNr,
        previousLifeStageVersion!.ammenObjectId,
        previousLifeStageVersion!
      );
      const { belegNr, ammenObjectId } = identificator!;
      const updatedEntity = SauService.modifySau(newSau, prozessEvents, belegNr, ammenObjectId);

      return await this.sauen.bulkPut([updatedEntity, previousSauVersion]);
    }
  };

  deleteSauenHistory = async (data: IDeleteProzessEventsByRecordRequestDto) => {
    const { recordId, funktionId } = data;
    const sauenData: ILocalSauDto[] = [];
    let currentStage: ILocalSauLifeStageDto[] = [];

    // Find existing sau in the local db.
    await this.sauen.each(sau =>
      sau.lifeStages.forEach(stage =>
        stage.funktionenHistory?.forEach(
          history =>
            history.funktionId === funktionId &&
            history.recordId === recordId &&
            sauenData.push(sau) &&
            currentStage.push(stage)
        )
      )
    );

    if (sauenData.length && currentStage.length) {
      // Find history that should be delete.
      const previousLifeStageVersion = currentStage[0].funktionenHistory!.find(
        history => history.funktionId === funktionId && history.recordId === recordId
      )?.previousData;

      if (previousLifeStageVersion) {
        // Return previous info about sau.
        const previousSauVersion = SauService.applyUpdatedLifeStage(
          sauenData[0],
          previousLifeStageVersion!.belegNr,
          previousLifeStageVersion!.ammenObjectId,
          previousLifeStageVersion!
        );
        return await this.sauen.bulkPut([previousSauVersion]);
      } else {
        // Remove history for current funktion and check if sau was used in another funktion.
        const filteredHistory = currentStage[0].funktionenHistory!.filter(
          history => history.funktionId !== funktionId && history.recordId !== recordId
        );
        const updatedLifeStage: ILocalSauLifeStageDto = {
          ...currentStage[0],
          funktionenHistory: filteredHistory,
        };

        const updatedSau = SauService.applyUpdatedLifeStage(
          sauenData[0],
          updatedLifeStage.belegNr,
          updatedLifeStage.ammenObjectId,
          updatedLifeStage
        );

        return await this.sauen.bulkPut([updatedSau]);
      }
    }
  };

  addBuchten = async (buchten: ILocalBuchtDto[]) => await this.buchten.bulkAdd(buchten);

  deleteAllBuchten = async () => await this.buchten.clear();

  updateBuchten = async (buchten: ILocalBuchtDto[]) => await this.buchten.bulkPut(buchten);

  getBuchtById = async (value: number) => await this.buchten.where("id").equals(value).toArray();

  updateBuchtData = async (
    newBuchtId: number | undefined,
    prozessEvents: IProzessEventsWithAdditionalData[]
  ) => {
    const { funktionId, recordId } = prozessEvents[0];
    let existingBucht: ILocalBuchtDto[] = [];

    // Find all existing bucht in the local db for specific recordId.
    await this.buchten.each(item =>
      item.funktionenHistory?.forEach(
        history =>
          history.funktionId === funktionId && history.recordId === recordId && existingBucht.push(item)
      )
    );

    // Simple update for current bucht.
    if (!newBuchtId && existingBucht.length) {
      const updatedEntity = BuchtService.modifyBuchtEditMode(existingBucht[0], prozessEvents);
      return await this.buchten.bulkPut([updatedEntity]);
    }

    // Current bucht was changed to another bucht.
    if (newBuchtId) {
      const newBucht = await this.getBuchtById(newBuchtId);

      const previousBuchtVersion = existingBucht[0].funktionenHistory!.find(
        history => history.funktionId === funktionId && history.recordId === recordId
      )?.previousData as ILocalBuchtDto;

      // Copy funktionHistory from removed bucht to new bucht.
      const inheritedHistory = existingBucht[0].funktionenHistory?.find(
        history => history.funktionId === funktionId && history.recordId === recordId
      );

      const buchtToUpdate = {
        ...newBucht[0],
        funktionenHistory: [...(newBucht[0].funktionenHistory || []), inheritedHistory!],
      };
      const updatedEntity = BuchtService.modifyBuchtEditMode(buchtToUpdate, prozessEvents);

      return await this.buchten.bulkPut([previousBuchtVersion, updatedEntity]);
    }
  };

  deleteBuchtenHistory = async (data: IDeleteProzessEventsByRecordRequestDto) => {
    const { recordId, funktionId } = data;
    const buchten: ILocalBuchtDto[] = [];

    // Find existing bucht in the local db.
    await this.buchten.each(bucht =>
      bucht.funktionenHistory?.find(
        history => history.funktionId === funktionId && history.recordId === recordId && buchten.push(bucht)
      )
    );

    if (buchten.length) {
      // Find history that should be delete.
      const historyForDeleting = buchten[0].funktionenHistory?.find(
        history => history.funktionId === funktionId && history.recordId === recordId
      );

      if (historyForDeleting && historyForDeleting.previousData) {
        // Return previous info about bucht if it exist.
        return await this.buchten.bulkPut([historyForDeleting.previousData]);
      } else {
        // Remove history for current funktion.
        const filteredHistory = buchten[0].funktionenHistory?.filter(
          history => history.funktionId !== funktionId && history.recordId !== recordId
        );

        const updatedBucht = {
          ...buchten[0],
          funktionenHistory: filteredHistory,
        };
        return await this.buchten.bulkPut([updatedBucht]);
      }
    }
  };

  addPlanFerkel = async (planFerkel: IPlanFerkelDto[]) => {
    await this.planFerkel.bulkAdd(planFerkel);
  };

  deletePlanFerkel = async () => await this.planFerkel.clear();

  getPlanFerkel = async () => await this.planFerkel.toArray();

  addCapturedRecords = async (
    prozessEvents: IProzessEventsWithAdditionalData[],
    validationErrors: IProzessEventsValidationResultDto | undefined,
    synced: boolean
  ) => {
    const records = prozessEvents.reduce(
      (obj: { [recordsGroupId: string]: ISyncedCapturedRecord }, prozessEvent) => {
        const existingRecord = obj[prozessEvent.recordsGroupId!];
        const existingData = existingRecord ? existingRecord.data : undefined;
        const setData = (data: { [workflowId: string]: any } | undefined) => {
          if (!!data && data[prozessEvent.workflowId]) {
            if (Array.isArray(data[prozessEvent.workflowId])) {
              return {
                ...data,
                [prozessEvent.workflowId]: [...data[prozessEvent.workflowId], prozessEvent.data],
              };
            } else {
              return {
                ...data,
                [prozessEvent.workflowId]: [data[prozessEvent.workflowId], prozessEvent.data],
              };
            }
          }
          return { ...data, [prozessEvent.workflowId]: prozessEvent.data };
        };

        const newRecord = {
          timestamp: new Date().getTime(),
          recordId: prozessEvent.recordId,
          recordsGroupId: prozessEvent.recordsGroupId,
          funktionId: prozessEvent.funktionId,
          data: setData(existingData),
          synced: synced ? 1 : 0,
          validationErrors,
          processingState: validationErrors ? ProcessingState.Failed : undefined,
        };

        // @ts-ignore
        obj[prozessEvent.recordsGroupId] = newRecord;
        return obj;
      },
      {}
    );

    await this.capturedRecords.bulkAdd(Object.values(records));
  };

  updateCapturedRecords = async (
    prozessEvents: IProzessEventsWithAdditionalData[],
    groupId: string,
    dataForDeletion: {
      valueForDeletion: { value: string; recordId: string }[] | undefined;
      idForDeletion: number;
    }
  ) => {
    const { valueForDeletion, idForDeletion } = dataForDeletion;

    // Find captured record which was edited.
    const capturedRecord = await this.capturedRecords.where("recordsGroupId").equals(groupId).toArray();
    let data = capturedRecord[0].data;

    if (valueForDeletion && idForDeletion && capturedRecord[0].data![idForDeletion]) {
      // Filter deleted data for prozess which can create multiple prozess events.
      // Works only for prozess which can create multiple prozess events, because it can has more then one prozess event data.
      data![idForDeletion] = data![idForDeletion].filter(
        (item: { value: string; recordId: string }) =>
          !valueForDeletion.some(value => value.recordId === item.recordId)
      );
    }

    if (!!prozessEvents && prozessEvents.length !== 0) {
      // Works for created prozess events in edit mode.
      // Map prozess event data by workflowId.
      const editedData = prozessEvents.map(pe => ({ [pe.workflowId]: pe.data }));

      const workflowIdWithMultipleData = idForDeletion;

      editedData.forEach((el: any) => {
        if (el[workflowIdWithMultipleData]) {
          // For prozess which can create a lot of prozess events add new data to existing.
          data = {
            ...data,
            [workflowIdWithMultipleData]: [
              ...data![workflowIdWithMultipleData],
              ...el[workflowIdWithMultipleData],
            ],
          };
        } else {
          // Rewrite another prozess data (which can't create a lot of prozess events).
          data = { ...data, ...el };
        }
      });
    }

    const updatedRecord = {
      ...capturedRecord[0],
      data,
      synced: prozessEvents.length ? 0 : 1,
    };

    await this.capturedRecords.bulkPut([updatedRecord]);
  };

  deleteCapturedRecord = async (recordsGroupId: string) => {
    await this.capturedRecords.bulkDelete([recordsGroupId]);
  };

  restoreCapturedRecord = async (recordsGroupId: string) => {
    const recordToRestore = await this.capturedRecords
      .where("dependsOnRecordGroupId")
      .equals(recordsGroupId)
      .toArray();

    if (recordToRestore.length && recordToRestore[0].previousData) {
      await this.capturedRecords.bulkPut([recordToRestore[0].previousData]);
    }
  };

  markCapturedRecordsAsSynced = async (prozessEvents: IProzessEventsWithAdditionalData[]) => {
    const recordsGroupIds = prozessEvents.reduce((obj: string[], prozessEvent) => {
      if (!obj.includes(prozessEvent.recordsGroupId!)) {
        obj.push(prozessEvent.recordsGroupId!);
      }

      return obj;
    }, []);

    await this.capturedRecords.where("recordsGroupId").anyOf(recordsGroupIds).modify({ synced: 1 });
  };

  removeOldSyncedRecords = async (funktionId: number, limit: number) => {
    // get latest allowed record for current funktion
    const lastAllowedRecordForCurrentFunktion = await this.capturedRecords
      .where("funktionId")
      .equals(funktionId)
      .offset(limit)
      .first();

    if (lastAllowedRecordForCurrentFunktion) {
      logTrace("Last allowed record", lastAllowedRecordForCurrentFunktion.data);

      // get all synced records which are older than the last allowed
      const recordsToDelete = await this.capturedRecords
        .where("timestamp")
        .below(lastAllowedRecordForCurrentFunktion.timestamp)
        .and(item => item.synced === 1)
        .and(item => item.funktionId === funktionId)
        .first();

      logTrace("Found records for deletion", recordsToDelete);

      if (recordsToDelete) {
        logTrace("Records to delete", recordsToDelete);

        await this.capturedRecords.bulkDelete([recordsToDelete.recordsGroupId!]);
      }
    }
  };

  getCapturedRecords = async (funktionId: number) =>
    await this.capturedRecords
      .orderBy("timestamp")
      .reverse()
      .filter(item => item.funktionId === funktionId)
      .toArray();

  getCapturedRecordByRecordId = async (recordId: string) =>
    await this.capturedRecords.where("recordId").equals(recordId).first();

  getAllCapturedRecords = async () => await this.capturedRecords.toArray();

  applyModifiedRecords = async (records: ISyncedCapturedRecord[]) =>
    await this.capturedRecords.bulkPut(records);

  checkModifiedRecords = async (
    funktionId: number,
    transponderToRestore: number,
    transponderToRemove: number,
    recordsGroupId: string
  ) => {
    const records = await this.getCapturedRecords(funktionId);

    const modifiedRecords = records.reduce(
      (total: ISyncedCapturedRecord[], current: ISyncedCapturedRecord) => {
        if (current.previousData) {
          let shouldReturnPreviousData = false;
          const data = current.previousData.data;
          for (let key in data) {
            if (Array.isArray(data[key])) {
              shouldReturnPreviousData = !!data[key].find(
                (transponderInfo: any) => transponderInfo.value === transponderToRestore
              );
            }
          }

          return [...total, shouldReturnPreviousData ? current.previousData : current];
        } else {
          let recordData = current.data;
          let shouldCreatePreviousData = false;

          for (let key in recordData) {
            if (Array.isArray(recordData[key])) {
              shouldCreatePreviousData =
                recordData[key].findIndex(
                  (transponderInfo: any) => transponderInfo.value === transponderToRemove
                ) >= 0;

              recordData = {
                ...recordData,
                [key]: recordData[key].filter(
                  (transponderInfo: any) => transponderInfo.value !== transponderToRemove
                ),
              };
            }
          }

          const updatedRecord: ISyncedCapturedRecord = {
            ...current,
            data: recordData,
            previousData: shouldCreatePreviousData ? current : undefined,
            dependsOnRecordGroupId: recordsGroupId,
          };
          return [...total, updatedRecord];
        }
      },
      []
    );

    await this.capturedRecords.bulkPut(modifiedRecords);
  };
}

const db = new AppDatabase();
export default db;
