import { useCallback, useReducer } from "react";
import { initialState, comboBoxReducer } from "./combo-box.reducer";
import { IProzessDto, ProzessType } from "../../../api/backend-api-v7";
import {
  ProzessEventOperationTypes,
  ProzessInputData,
} from "../../../store/prozess-events/prozess-events.actions";
import { ComboBoxActionType } from "./combo-box.types";
import { BluetoothDataFormat } from "../../../store/bluetooth/bluetooth-data-format";
import { TRANSPONDER_VALID_LENGTH } from "../transponder/transponder.component";

export const useComboBoxState = (
  prozess: IProzessDto,
  onChanged: (prozessInputData: ProzessInputData) => void,
  isEditing: boolean,
  optionsData: string[],
  createFilteredBy: (prozessInputData: ProzessInputData) => void
) => {
  const [state, dispatch] = useReducer(comboBoxReducer, initialState);
  const { isRequired, shouldKeepValue } = prozess;

  const isValueValid = useCallback(
    (value: string | undefined) => {
      switch (prozess.prozessType) {
        case ProzessType.TIER_IDENT: {
          return !!optionsData?.find(item => item === value);
        }
        case ProzessType.TRANSPONDER: {
          return (
            !!optionsData?.find(item => item === value) &&
            value?.toString().trim().length === TRANSPONDER_VALID_LENGTH &&
            Number(value) > 0
          );
        }
        default:
          return !!value;
      }
    },
    [prozess.prozessType, optionsData]
  );

  const init = useCallback(
    () =>
      dispatch({
        type: ComboBoxActionType.INIT,
        payload: { value: "", isRequired, shouldKeepValue, status: ProzessEventOperationTypes.DELETE },
      }),
    [isRequired, shouldKeepValue]
  );

  const onProzessDataChanged = useCallback(
    (value: string | undefined, metaData?: BluetoothDataFormat) => {
      dispatch({
        type: ComboBoxActionType.PROZESS_DATA_CHANGED,
        payload: {
          value,
          metaData,
          isRequired,
          isValid: isValueValid(value),
          shouldKeepValue,
          status: ProzessEventOperationTypes.CREATE,
        },
      });
    },
    [isRequired, isValueValid, shouldKeepValue]
  );

  const reset = useCallback(
    () =>
      dispatch({
        type: ComboBoxActionType.RESET,
        payload: { value: "", isRequired, shouldKeepValue, status: ProzessEventOperationTypes.DELETE },
      }),
    [isRequired, shouldKeepValue]
  );

  const revealEventType = useCallback(
    (metaData?: BluetoothDataFormat) => {
      const { eventType, manualEventType, changeEventType, changeManualEventType } = prozess;
      switch (prozess.prozessType) {
        case ProzessType.TIER_IDENT:
        case ProzessType.TRANSPONDER: {
          return isEditing && metaData
            ? changeEventType
            : isEditing && !metaData
            ? changeManualEventType
            : !isEditing && metaData
            ? eventType
            : manualEventType;
        }
        default:
          return isEditing ? changeEventType : eventType;
      }
    },
    [isEditing, prozess]
  );

  const revealData = useCallback(
    (value: string | undefined) => {
      if (value) {
        switch (prozess.prozessType) {
          case ProzessType.TRANSPONDER: {
            return Number(value);
          }
          case ProzessType.TIER_IDENT: {
            return value;
          }
          default: {
            const data = prozess.data!.find(item => item.label === value);
            if (data) {
              const { belegNr, ammenObjectId } = data.additional!;
              return belegNr ? { tierSysId: data.id, belegNr } : { tierSysId: data.id, ammenObjectId };
            }
          }
        }
      }
    },
    [prozess.data, prozess.prozessType]
  );

  const save = useCallback(
    (value: string | undefined, metaData?: BluetoothDataFormat) => {
      onChanged({
        workflowId: prozess.workflowId,
        eventType: revealEventType(metaData),
        eventCategory: prozess.eventCategory,
        data: revealData(value),
        metaData,
        additionalData: undefined,
        label: prozess.data?.find(d => d.label === value)?.label,
        operation: value ? ProzessEventOperationTypes.CREATE : ProzessEventOperationTypes.DELETE,
        isValid: prozess.isRequired ? !!value : true,
        prozessType: prozess.prozessType,
      } as ProzessInputData);
    },
    [
      onChanged,
      prozess.workflowId,
      prozess.eventCategory,
      prozess.data,
      prozess.isRequired,
      prozess.prozessType,
      revealEventType,
      revealData,
    ]
  );

  const shouldEditProzessData = useCallback(
    // toggle this action if you need to create new prozess in edit mode.
    (isProzessEdited: boolean) =>
      dispatch({
        type: ComboBoxActionType.PROZESS_DATA_EDITED,
        payload: {
          value: "",
          isRequired,
          shouldKeepValue,
          status: ProzessEventOperationTypes.EDIT,
          isProzessEdited,
        },
      }),
    [isRequired, shouldKeepValue]
  );

  const createFilteredByValue = useCallback(
    // Create FilteredBy value in edit mode.
    (editedValue: string | undefined) => {
      createFilteredBy({
        workflowId: prozess.workflowId,
        eventType: prozess.eventType,
        data: revealData(editedValue),
        label: editedValue,
        metaData: undefined,
        additionalData: undefined,
        operation: editedValue ? ProzessEventOperationTypes.CREATE : ProzessEventOperationTypes.DELETE,
        isValid: prozess.isRequired ? !!editedValue : true,
      } as ProzessInputData);
    },
    [createFilteredBy, prozess.eventType, prozess.isRequired, prozess.workflowId, revealData]
  );

  return { state, init, reset, onProzessDataChanged, save, shouldEditProzessData, createFilteredByValue };
};
