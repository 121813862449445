import { IProzessDto } from "../../../api/backend-api-v7";
import {
  ProzessEventOperationTypes,
  ProzessInputData,
} from "../../../store/prozess-events/prozess-events.actions";
import { useCallback, useEffect, useReducer, useState } from "react";
import { ferkelGridReducer, initialState } from "./ferkel-grid.reducer";
import { FerkelGridActionType, FerkelGridProps, FerkelGridState } from "./ferkel-grid.types";
import { DISPLAYED_BUTTONS_AMOUNT } from "./ferkel-grid.component";
import { ILocalFerkelDto } from "../../../db/database";

export const useFerkelGridState = (
  prozess: IProzessDto,
  onChanged: (prozessInputData: ProzessInputData) => void
) => {
  const [state, dispatch] = useReducer(ferkelGridReducer, initialState);

  const { isRequired, shouldKeepValue } = prozess;

  const init = useCallback(
    () =>
      dispatch({
        type: FerkelGridActionType.INIT,
        payload: {
          value: undefined,
          isRequired,
          shouldKeepValue,
          status: ProzessEventOperationTypes.DELETE,
        },
      }),
    [isRequired, shouldKeepValue]
  );

  const onProzessDataChanged = useCallback(
    (value: ILocalFerkelDto) => {
      dispatch({
        type: FerkelGridActionType.PROZESS_DATA_CHANGED,
        payload: { value, isRequired, shouldKeepValue, status: ProzessEventOperationTypes.CREATE },
      });
    },
    [isRequired, shouldKeepValue]
  );

  const toggle = useCallback(
    (value: ILocalFerkelDto) => {
      dispatch({
        type: FerkelGridActionType.TOGGLE,
        payload: { value, isRequired, shouldKeepValue, status: ProzessEventOperationTypes.CREATE },
      });
    },
    [isRequired, shouldKeepValue]
  );

  const reset = useCallback(
    () =>
      dispatch({
        type: FerkelGridActionType.RESET,
        payload: { value: undefined, isRequired, shouldKeepValue, status: ProzessEventOperationTypes.DELETE },
      }),
    [isRequired, shouldKeepValue]
  );

  const save = useCallback(
    (selectedFerkel: ILocalFerkelDto | undefined) => {
      onChanged({
        workflowId: prozess.workflowId,
        eventType: prozess.eventType,
        eventCategory: prozess.eventCategory,
        data: selectedFerkel?.tierIdent,
        metaData: undefined,
        additionalData: selectedFerkel
          ? { ...selectedFerkel, genetischesau: selectedFerkel.genetischeSauDescriptor.tierSysId }
          : undefined,
        operation: selectedFerkel ? ProzessEventOperationTypes.CREATE : ProzessEventOperationTypes.DELETE,
        isValid: prozess.isRequired ? !!selectedFerkel : true,
        prozessType: prozess.prozessType,
      } as ProzessInputData);
    },
    [onChanged, prozess]
  );

  return { state, init, reset, onProzessDataChanged, toggle, save };
};

export const useFerkelGrid = (props: FerkelGridProps, state: FerkelGridState, reset: () => void) => {
  const { prozess, filteredBy, ferkel } = props;

  const [filteredFerkel, setFilteredFerkel] = useState(new Array<ILocalFerkelDto>());
  const [displayedFerkel, setDisplayedFerkel] = useState(new Array<ILocalFerkelDto>());
  const [allFerkel, setAllFerkel] = useState(new Array<ILocalFerkelDto>());

  const sortFerkel = useCallback(
    (data: ILocalFerkelDto[]) =>
      !!data && data.length !== 0
        ? data.filter(p => !!p.tierIdent).sort((a, b) => a.tierIdent!.localeCompare(b.tierIdent!))
        : [],
    []
  );

  const setFerkelForDisplaying = useCallback(() => {
    const newDisplayedFerkel = state.ferkel ? [state.ferkel] : [];
    const isFerkelAlreadyExist =
      !!state.ferkel && filteredFerkel.find(item => item.tierIdent === newDisplayedFerkel[0].tierIdent);

    if (!isFerkelAlreadyExist) {
      setDisplayedFerkel(
        sortFerkel(
          Array.from(new Set(newDisplayedFerkel.concat(filteredFerkel))).slice(0, DISPLAYED_BUTTONS_AMOUNT)
        )
      );
    } else {
      setDisplayedFerkel(sortFerkel(filteredFerkel.slice(0, DISPLAYED_BUTTONS_AMOUNT)));
    }
  }, [filteredFerkel, sortFerkel, state.ferkel]);

  useEffect(() => {
    setFerkelForDisplaying();
  }, [setFerkelForDisplaying]);

  useEffect(() => {
    let ferkelForCurrentBuchtsau: ILocalFerkelDto[];

    if (filteredBy) {
      ferkelForCurrentBuchtsau = ferkel.filter(
        (f: ILocalFerkelDto) => f.buchtsauDescriptor.tierSysId === filteredBy.data.tierSysId
      );
    } else {
      ferkelForCurrentBuchtsau = [];
    }

    const sortedFerkel = sortFerkel(ferkelForCurrentBuchtsau);
    setFilteredFerkel(sortedFerkel);

    setAllFerkel(sortFerkel(ferkel));
  }, [filteredBy, prozess.data, reset, sortFerkel, ferkel]);

  return { allFerkel, displayedFerkel, filteredFerkel };
};
