import logger from "../../logger";
import I18n from "i18next";
import db, { ISyncedCapturedRecord } from "../../db/database";
import { Dispatch } from "redux";
import { Action } from "../action";
import { call, put, takeEvery, select } from "redux-saga/effects";
import * as Sentry from "@sentry/browser";

import BackendClient from "../../api/backend-client";
import {
  IDeleteProzessEventsByRecordRequestDto,
  IFunktionDto,
  IProzessDto,
  EventCategory,
  ProcessingState,
  ApiException,
} from "../../api/backend-api-v7";
import { EXISTING_PROZESS_EVENTS_API, LAST_PROZESS_EVENTS_API_VERSION } from "../../api/api-interfaces";

import {
  saveProzessEventsFailure,
  saveProzessEventsSuccess,
  updateProzessEvents,
  ProzessEventsActionType,
  clearProzessEvents,
  ProzessInputData,
  setProzessEvent,
  setMultipleProzessEvent,
  deleteProzessEvent,
  ProzessEventOperationTypes,
  setGivenData,
  setFilteredBy,
  deleteFilteredBy,
  prozessEventsNotValid,
  prozessEventsAreValid,
  createNewRecord,
  setComparisonData,
  deleteComparisonData,
  createFilteredBy,
  deleteMultipleProzessEvent,
  startSynchronousProcessing,
  finishSynchronousProcessing,
} from "./prozess-events.actions";
import {
  clearTransponders,
  setValidationErrors,
  TransponderForDeletion,
} from "../transitory-prozess-data/transitory-prozess-data.actions";
import {
  confirmSaveProzessEvents,
  prozessEventsAreSaved,
  prozessEventsAreSynced,
  syncProzessEvents,
  syncProzessEventsFailure,
  syncProzessEventsSuccess,
} from "../synchronization/synchronization.actions";
import { highlightRecord, toggleDataImportSnackbar, toggleEditMode } from "../common/common.actions";
import {
  showWarningRequest,
  showSuccessfulRequest,
  showFailedRequest,
} from "../notifications/notifications.actions";
import { finishEditCapturedRecord } from "../captured-records/captured-records.actions";
import { getWurfuebersichtData } from "../wurfuebersicht/wurfuebersicht.actions";
import { updateFerkelData, updateFerkelDataEditMode } from "../ferkel/ferkel.actions";
import { updateSauData, updateSauDataEditMode } from "../sauen/sauen.actions";
import { updateBuchtData, updateBuchtDataEditMode } from "../buchten/buchten.actions";

import {
  getProzesseByFunktionId,
  getCurrentFunktionId,
  getProzessById,
  getProzesseWhichListenOnChangesOfCurrentProzess,
  getProzesseWhichAreFilteredByOtherProzesse,
  getComparisonProzesse,
  isProzessCanCreateMultipleEvents,
  getFunktionById,
} from "../funktionen/funktionen.selectors";
import {
  getProzessEvents,
  getCurrentRecordId,
  getAppVersion,
  getCurrentGroupId,
} from "./prozess-events.selectors";
import { getUserId } from "../authentication/authentication.selectors";
import { getIsManuallyEmpty, isEditModeActivated } from "../common/common.selectors";
import { getEditedCapturedRecord } from "../captured-records/captured-records.selectors";
import { getTranspondersForDeletion } from "../transitory-prozess-data/transitory-prozess-data.selectors";
import { getShouldRemoveObsoleteData } from "../synchronization/synchronization.selectors";

import { uptateTotalAmountOfGewicht } from "../transitory-prozess-data/transitory-prozess-data.saga";

import {
  tryToCreateProzessEventsForMultipleProzessEvents,
  extractDataFromProzessEvent,
  extractFilteredByData,
  revealProzessEvents,
  revealProzessEventsForCapturedRecord,
  isProzessEventsSynchronous,
  HandledProzessEvents,
  createValidationLogs,
} from "./prozess-events.utils";
import { IProzessEventsWithAdditionalData } from "../../pages/funktion/funktion.types";
import { v1 as uuidv1 } from "uuid";
import { ProzessEventsRecord } from "./prozess-events.reducer";
import { filterProzessEventsByEventCategory, verifyEventCategory } from "../../utils/event-category.utils";
import { LocalStorageItems } from "../../utils/local-storage.enum";
import { calculateExpirationDate } from "../../utils/datetime.utils";
import { isProzessEventValid } from "../../utils/validation";

const logError = logger.error("prozess-events.saga");
let backendClient: BackendClient;
const networkErrorMessage = "Network Error";
const { LAST_SYNC } = LocalStorageItems;

export function* trySendProzessEvents(prozessEvents: IProzessEventsWithAdditionalData[]): any {
  try {
    const isDataObsolete: boolean = yield select(getShouldRemoveObsoleteData);

    if (isDataObsolete) {
      return;
    }

    const pe = prozessEvents.reduce(
      (
        total: { [key: string]: IProzessEventsWithAdditionalData[] },
        value: IProzessEventsWithAdditionalData
      ) => {
        if (!value.additionalData.isReadOnly) {
          return {
            ...total,
            [value.apiVersion]: [
              ...total[value.apiVersion],
              { ...value, data: typeof value.data === "string" ? value.data : JSON.stringify(value.data) },
            ],
          };
        }
        return total;
      },
      EXISTING_PROZESS_EVENTS_API
    );

    yield call([backendClient, "sendProzessEvents"], pe);
    yield put(syncProzessEventsSuccess(prozessEvents));
    yield call([db, "markSyncedProzessEvents"], prozessEvents);
    yield call([db, "removeSyncedProzessEvents"]);
    yield put(confirmSaveProzessEvents());

    const prozessEventsCount: number = yield call(db.getCountOfUnsyncedProzessEvents);
    yield put(prozessEventsAreSynced(!prozessEventsCount));
    yield call([localStorage, "setItem"], LAST_SYNC, JSON.stringify(new Date().getTime()));
  } catch (e: unknown) {
    const apiException = e as ApiException;

    const prozessEventsCount: number = yield call(db.getCountOfUnsyncedProzessEvents);
    yield put(prozessEventsAreSynced(!prozessEventsCount));
    yield put(syncProzessEventsFailure());

    /** BE validation failed */
    if (apiException.status === 400) {
      Sentry.withScope(function (scope) {
        scope.setTag("pe-validation", "validation");
        scope.setLevel("error");
        scope.setExtra("PE validation result", JSON.stringify(apiException.response));
        Sentry.captureException(apiException);
      });

      /** Save recordId of invalid PE to exclude other PE with the same recordId. */
      const invalidRecordIdsToExclude = prozessEvents.reduce((total: string[], current) => {
        const isValid = isProzessEventValid(current);

        if (!isValid) {
          return [...total, current.recordId];
        }

        return total;
      }, []);

      /** Send all valid events in case of validation error. */
      const validEvents = prozessEvents.filter(event => !invalidRecordIdsToExclude.includes(event.recordId));

      yield call(trySendProzessEvents, validEvents);
    }
  }
}

export function* sendProzessEventsSynchronously(prozessEvents: IProzessEventsWithAdditionalData[]) {
  try {
    const pe = prozessEvents.map(pe => ({ ...pe, data: JSON.stringify(pe.data) }));

    yield call([backendClient, "sendProzessEventsSynchronously"], pe);
    yield put(saveProzessEventsSuccess({ prozessEvents, validationErrors: undefined }));
    yield put(showSuccessfulRequest(I18n.t("NOTIFICATIONS.SUCCESS_DATA_IMPORT")));
    yield put(syncProzessEventsSuccess(prozessEvents));
    yield put(createNewRecord());
  } catch (e: any) {
    logError("Synchronous request failed", e);

    // To be sure that PE was validated.
    if (e.generalMessages || e.rowValidationMessages) {
      yield put(saveProzessEventsFailure({ prozessEvents, validationErrors: e }));
      yield put(setValidationErrors(e));
      yield put(toggleDataImportSnackbar(true));
      yield put(createNewRecord());
    }
    if (e.message === networkErrorMessage) {
      yield put(finishSynchronousProcessing());
      yield put(showFailedRequest(I18n.t("NOTIFICATIONS.ONLINE_ONLY_FUNCTION")));
    }
  }
}

export function* updateLocalEntities(prozessEvents: IProzessEventsWithAdditionalData[]) {
  try {
    const isEditMode: boolean = yield select(isEditModeActivated);

    if (isEditMode) {
      yield put(
        updateFerkelDataEditMode(
          filterProzessEventsByEventCategory(prozessEvents, [
            EventCategory.Ferkel,
            EventCategory.VerkaufCancellationAction,
          ])
        )
      );
      yield put(
        updateSauDataEditMode(filterProzessEventsByEventCategory(prozessEvents, [EventCategory.Sau]))
      );
      yield put(
        updateBuchtDataEditMode(filterProzessEventsByEventCategory(prozessEvents, [EventCategory.Bucht]))
      );
    } else {
      yield put(
        updateFerkelData(
          filterProzessEventsByEventCategory(prozessEvents, [
            EventCategory.Ferkel,
            EventCategory.VerkaufCancellationAction,
          ])
        )
      );
      yield put(updateSauData(filterProzessEventsByEventCategory(prozessEvents, [EventCategory.Sau])));
      yield put(updateBuchtData(filterProzessEventsByEventCategory(prozessEvents, [EventCategory.Bucht])));
    }
  } catch (e: any) {
    logError("Failed to update local entities", e);
  }
}

export function* allProzessEventsValidInCreationMode(prozessEvents: {
  [workflowId: number]: IProzessEventsWithAdditionalData;
}) {
  // Check that user created all prozesse with isRequired flag = true in creation mode.
  // Only for creation mode!
  const funktionId: number = yield select(getCurrentFunktionId);
  const prozesse: IProzessDto[] | undefined = yield select(getProzesseByFunktionId(funktionId));

  if (prozesse) {
    return prozesse.every(prozess => (prozess.isRequired ? !!prozessEvents[prozess.workflowId!] : true));
  }

  return false;
}

export function* trySaveProzessEvents(action: Action<null>) {
  const prozessEventsRecord: ProzessEventsRecord = yield select(getProzessEvents);
  const isEditMode: boolean = yield select(isEditModeActivated);
  const dataForDeletion: { value: string; recordId: string | undefined }[] = yield select(
    getTranspondersForDeletion
  );

  if (Object.entries(prozessEventsRecord).length === 0 && !dataForDeletion.length) {
    // Stop saga if the user didn't create any prozesse in edit mode.
    yield put(showWarningRequest(I18n.t("NOTIFICATIONS.NO_CHANGES")));
    return;
  }

  let areValid = false;
  if (!isEditMode) {
    areValid = yield call(allProzessEventsValidInCreationMode, prozessEventsRecord);
  }
  // In edit mode we don't need check if all prozessEvents valid, only validation in components.
  if (isEditMode ? true : areValid) {
    yield put(prozessEventsAreSynced(true));
    yield put(prozessEventsAreValid());

    // try to clone all prozess events for each multiple prozess event with unique recordId if it exists.
    const handledProzessEvents = tryToCreateProzessEventsForMultipleProzessEvents(
      Object.values(prozessEventsRecord)
    );
    const prozessEvents = revealProzessEvents(handledProzessEvents);

    yield call(updateLocalEntities, prozessEvents);
    yield call(uptateTotalAmountOfGewicht, prozessEvents);
    yield call(saveProzessEvents, handledProzessEvents);

    const prozessEventsCount: number = yield call(db.getCountOfUnsyncedProzessEvents);
    yield put(prozessEventsAreSynced(prozessEventsCount === 0));

    yield put(highlightRecord(true));
    yield put(getWurfuebersichtData(prozessEventsRecord));

    if (verifyEventCategory(prozessEvents, EventCategory.Export)) {
      yield put(showSuccessfulRequest(I18n.t("NOTIFICATIONS.SUCCESS_DATA_EXPORT")));
    } else {
      if (!verifyEventCategory(prozessEvents, EventCategory.Datenimport)) {
        yield put(showSuccessfulRequest(I18n.t("NOTIFICATIONS.SUCCESS_SAVE_RECORD")));
      }
    }

    if (isEditMode) {
      // Close edit mode.
      yield put(finishEditCapturedRecord());
      yield put(toggleEditMode(false));
    }

    yield put(highlightRecord(false));
  } else {
    yield put(prozessEventsNotValid());
  }
}

export function* saveProzessEvents(prozessEvents: IProzessEventsWithAdditionalData[] | HandledProzessEvents) {
  try {
    const isEditMode: boolean = yield select(isEditModeActivated);
    const isSynchronous = isProzessEventsSynchronous(prozessEvents);
    const prozessEventsForCapturedRecord = revealProzessEventsForCapturedRecord(prozessEvents);
    const modifiedProzessEvents = revealProzessEvents(prozessEvents);

    if (isSynchronous) {
      yield put(startSynchronousProcessing());
      yield call(sendProzessEventsSynchronously, modifiedProzessEvents);
      return;
    }

    /** Log info if PE lost userId or RecordId */
    const logs = createValidationLogs(modifiedProzessEvents, "Add event to DB");
    if (logs.length) {
      yield call([db, "addValidationLogs"], logs);
    }

    yield call([db, "addProzessEvents"], modifiedProzessEvents);
    yield put(prozessEventsAreSaved());
    yield put(clearProzessEvents());
    if (isEditMode) {
      yield put(updateProzessEvents(prozessEventsForCapturedRecord));
    } else {
      yield put(
        saveProzessEventsSuccess({
          prozessEvents: prozessEventsForCapturedRecord,
          validationErrors: undefined,
        })
      );
      yield put(createNewRecord());
    }
    yield put(syncProzessEvents());
  } catch (e: any) {
    logError("Could not add prozess events to db", e);
  }
}

export function* removeGivenDataForDeletedProzessEvent(workflowId: number) {
  try {
    const funktionId: number = yield select(getCurrentFunktionId);
    const prozessChanges: IProzessDto[] = yield select(
      getProzesseWhichListenOnChangesOfCurrentProzess(funktionId, workflowId)
    );

    for (const prozess of prozessChanges) {
      yield put(
        setGivenData({
          workflowId: prozess.workflowId,
          eventType: prozess.eventType!,
          data: "",
        })
      );
    }
  } catch (e: any) {
    logError("Could not delete prozess event", e.message);
  }
}

export function* deleteFilteredBys(workflowId: number) {
  try {
    const funktionId: number = yield select(getCurrentFunktionId);
    const filteredProzesse: IProzessDto[] = yield select(
      getProzesseWhichAreFilteredByOtherProzesse(funktionId, workflowId)
    );

    for (const prozess of filteredProzesse) {
      yield put(deleteFilteredBy(prozess.workflowId!));
    }
  } catch (e: any) {
    logError("Could not delete filteredBy from state", e.message);
  }
}

export function* deleteComparisonDataById(workflowId: number) {
  try {
    const funktionId: number = yield select(getCurrentFunktionId);
    const filteredProzesse: IProzessDto[] = yield select(getComparisonProzesse(funktionId, workflowId));

    for (const prozess of filteredProzesse) {
      yield put(deleteComparisonData(prozess.workflowId!));
    }
  } catch (e: any) {
    logError("Could not delete comparison data from state", e.message);
  }
}

export function* updateComparisonData() {
  try {
    const funktionId: number = yield select(getCurrentFunktionId);
    const editedCapturedRecord: ISyncedCapturedRecord = yield select(getEditedCapturedRecord);
    const prozesse: IProzessDto[] = yield select(getProzesseByFunktionId(funktionId));

    // Update comparison data for prozesse which shouldHighlightDifferenceToWorkflowId: remove value for creation mode, add value for edit mode.
    // It's prevents errors when edit mode was activated.
    const editedWorkflowIds = Object.keys(editedCapturedRecord.data!);
    const comparisonProzesse = editedWorkflowIds.reduce((total: IProzessDto[], workflowId: string) => {
      const comporisonProzess = prozesse.filter(
        (prozess: IProzessDto) => prozess.shouldHighlightDifferenceToWorkflowId === +workflowId
      );
      if (comporisonProzess.length > 0) {
        total.push(comporisonProzess[0]);
      }
      return total;
    }, []);

    // Remove comparison data for creation mode.
    for (const prozess of comparisonProzesse) {
      yield put(deleteComparisonData(prozess.workflowId!));
    }

    // Add comparison data for edited value in edit mode.
    for (const comparisonProzess of comparisonProzesse) {
      const prozessData = comparisonProzess.data!.find(
        pr => pr.label === editedCapturedRecord.data![comparisonProzess.workflowId!]
      );
      yield put(
        setComparisonData({
          sourceWorkflowId: comparisonProzess.shouldHighlightDifferenceToWorkflowId!,
          workflowId: (comparisonProzess as IProzessDto).workflowId!,
          eventType: (comparisonProzess as IProzessDto).eventType!,
          eventCategory: comparisonProzess.eventCategory!,
          data: prozessData!.id,
          label: comparisonProzess.label,
          metaData: undefined,
          additionalData: undefined,
          operation: ProzessEventOperationTypes.CREATE,
          isValid: true,
          prozessType: comparisonProzess.prozessType!,
        })
      );
    }
  } catch (e: any) {
    logError("Could not update comparison data", e.message);
  }
}

export function* createProzessEvents(prozessInputData: ProzessInputData) {
  try {
    const funktionId: number = yield select(getCurrentFunktionId);
    const funktion: IFunktionDto = yield select(getFunktionById(funktionId));
    const editedRecord: ISyncedCapturedRecord | undefined = yield select(getEditedCapturedRecord);
    const appVersion: string = yield select(getAppVersion);
    const userId: string = yield select(getUserId);
    const { shouldCallSynchronousProzessEventsProcessing } = funktion.funktionConfiguration!;

    let recordId: string;
    let recordsGroupId: string;
    if (editedRecord) {
      recordId = editedRecord.recordId!;
      recordsGroupId = editedRecord.recordsGroupId!;
    } else {
      recordId = yield select(getCurrentRecordId);
      recordsGroupId = yield select(getCurrentGroupId);
    }

    if (!prozessInputData.isValid) {
      return;
    }

    const prozessEventWithAdditionalData: IProzessEventsWithAdditionalData = {
      workflowId: prozessInputData.workflowId,
      eventType: prozessInputData.eventType,
      eventCategory: prozessInputData.eventCategory!,
      data: prozessInputData.data,
      metaData: prozessInputData.metaData,
      createdAtUtc: new Date(),
      funktionId,
      transactionId: uuidv1(),
      recordId,
      recordsGroupId,
      additionalData: { ...prozessInputData.additionalData, createdInEditMode: !!editedRecord },
      prozessType: prozessInputData.prozessType,
      appVersion,
      userId,
      apiVersion: LAST_PROZESS_EVENTS_API_VERSION,
      expirationDate: calculateExpirationDate(funktion),
      processingState: ProcessingState.New,
      synchronousProzessEvent: shouldCallSynchronousProzessEventsProcessing,
      label: prozessInputData.label,
    };

    /** Log info if PE lost userId or RecordId */
    if (!userId || !recordId) {
      yield call(
        [db, "addValidationLogs"],
        [
          {
            id: uuidv1(),
            createdAtUtc: prozessEventWithAdditionalData.createdAtUtc,
            info: `CreateProzessEvents saga: event transactionId: ${prozessEventWithAdditionalData.transactionId}, userId=${userId}, recordId=${recordId}`,
          },
        ]
      );
    }

    const canCreateMultipleEvents: boolean = yield select(
      isProzessCanCreateMultipleEvents(funktionId, prozessEventWithAdditionalData.workflowId)
    );

    if (canCreateMultipleEvents) {
      yield put(setMultipleProzessEvent(prozessEventWithAdditionalData));
    } else {
      yield put(setProzessEvent(prozessEventWithAdditionalData));
    }

    // get all prozesse which listens for changes for the workflowId used in newly created prozessEvents
    // and create prozessEvents for them
    const prozessChanges: IProzessDto[] = yield select(
      getProzesseWhichListenOnChangesOfCurrentProzess(funktionId, prozessEventWithAdditionalData.workflowId)
    );

    for (const prozess of prozessChanges) {
      const givenValue = extractDataFromProzessEvent(prozess, prozessEventWithAdditionalData);

      yield put(
        setGivenData({
          sourceWorkflowId: prozessInputData.workflowId,
          workflowId: prozess.workflowId,
          eventType: prozess.eventType!,
          data: givenValue,
          metaData: undefined,
          additionalData: undefined,
          operation: ProzessEventOperationTypes.CREATE,
          isValid: true,
        })
      );
    }

    const comparisonProzesse: IProzessDto[] = yield select(
      getComparisonProzesse(funktionId, prozessInputData.workflowId)
    );

    for (const comparisonProzess of comparisonProzesse) {
      yield put(
        setComparisonData({
          sourceWorkflowId: prozessInputData.workflowId,
          workflowId: (comparisonProzess as IProzessDto).workflowId!,
          eventType: (comparisonProzess as IProzessDto).eventType!,
          eventCategory: comparisonProzess.eventCategory!,
          data: prozessInputData.data[0],
          label: comparisonProzess.label,
          metaData: undefined,
          additionalData: undefined,
          operation: ProzessEventOperationTypes.CREATE,
          isValid: true,
          prozessType: prozessInputData.prozessType,
        })
      );
    }

    yield put(createFilteredBy(prozessInputData));
  } catch (e: any) {
    logError("Cant create prozess events", e.message);
  }
}

export function* deleteProzessEventSaga(prozessInputData: ProzessInputData) {
  try {
    const { workflowId } = prozessInputData;
    const funktionId: number = yield select(getCurrentFunktionId);
    const isManuallyEmpty: boolean = yield select(getIsManuallyEmpty);

    const prozessWithMultipleEvents: boolean = yield select(
      isProzessCanCreateMultipleEvents(funktionId, workflowId)
    );

    if (prozessWithMultipleEvents && !isManuallyEmpty && prozessInputData.data) {
      yield put(deleteMultipleProzessEvent(prozessInputData));
      yield put(showSuccessfulRequest("NOTIFICATIONS.SUCCESS_DELETE_TRANSPONDER"));
    } else {
      yield put(deleteProzessEvent(workflowId));
      yield call(removeGivenDataForDeletedProzessEvent, workflowId);
    }
  } catch (e: any) {
    logError("Cant delete prozess events", e.message);
  }
}

export function* prozessDataChanged(action: Action<ProzessInputData>) {
  const { payload } = action;
  const { workflowId, operation } = payload;

  if (operation === ProzessEventOperationTypes.CREATE) {
    yield call(createProzessEvents, payload);
  } else {
    yield call(deleteProzessEventSaga, payload);
    yield call(deleteFilteredBys, workflowId);
    yield call(deleteComparisonDataById, workflowId);
  }
}

export function* createFilteredByValue(action: Action<ProzessInputData>) {
  const prozessInputData = action.payload;
  const funktionId: number = yield select(getCurrentFunktionId);
  const recordId: string = yield select(getCurrentRecordId);
  const recordsGroupId: string = yield select(getCurrentGroupId);
  const userId: string = yield select(getUserId);

  const prozessEventWithAdditionalData: IProzessEventsWithAdditionalData = {
    workflowId: prozessInputData.workflowId,
    eventType: prozessInputData.eventType,
    eventCategory: prozessInputData.eventCategory!,
    data: Array.isArray(prozessInputData.data) ? prozessInputData.data[0] : prozessInputData.data,
    metaData: prozessInputData.metaData,
    createdAtUtc: new Date(),
    funktionId,
    transactionId: uuidv1(),
    recordId,
    recordsGroupId,
    additionalData: prozessInputData.additionalData,
    prozessType: prozessInputData.prozessType,
    appVersion: process.env.REACT_APP_APP_VERSION!,
    apiVersion: LAST_PROZESS_EVENTS_API_VERSION,
    userId,
    expirationDate: null,
    processingState: ProcessingState.New,
    label: prozessInputData.label,
  };

  const filteredProzesse: IProzessDto[] = yield select(
    getProzesseWhichAreFilteredByOtherProzesse(funktionId, prozessEventWithAdditionalData.workflowId)
  );

  for (const prozess of filteredProzesse) {
    const filteredBy = extractFilteredByData(prozess, prozessEventWithAdditionalData);

    let label: string | undefined;

    const filteringProzess: IProzessDto = yield select(getProzessById(funktionId, filteredBy.workflowId));

    if (filteringProzess?.data) {
      label = filteringProzess.data.find(d => d.value === filteredBy.data)?.label;
    }

    yield put(
      setFilteredBy({
        sourceWorkflowId: prozessInputData.workflowId,
        workflowId: prozess.workflowId!,
        eventType: prozess.eventType!,
        eventCategory: prozess.eventCategory!,
        data: filteredBy.data,
        label,
        metaData: undefined,
        additionalData: undefined,
        operation: ProzessEventOperationTypes.CREATE,
        isValid: true,
        prozessType: prozessInputData.prozessType,
      })
    );
  }
}

export function* deleteProzessEventsByRecordId(valueForDeletion: TransponderForDeletion[]) {
  try {
    const funktionId: number = yield select(getCurrentFunktionId);

    for (const value of valueForDeletion) {
      const requestData: IDeleteProzessEventsByRecordRequestDto = { recordId: value.recordId!, funktionId };
      yield call([backendClient, "deleteRecordByRecordId"], requestData);
      yield call([db, "deleteFerkelHistory"], requestData);
    }

    yield put(clearTransponders());
  } catch (e) {
    logError("Could not delete prozess event by record id", e);
  }
}

export default function* prozessEventsSaga(dispatch: Dispatch) {
  backendClient = BackendClient.getInstance(dispatch);
  yield takeEvery(ProzessEventsActionType.SAVE_PROZESS_EVENTS, trySaveProzessEvents);
  yield takeEvery(ProzessEventsActionType.PROZESS_DATA_CHANGED, prozessDataChanged);
  yield takeEvery(ProzessEventsActionType.CREATE_FILTERED_BY, createFilteredByValue);
  yield takeEvery(ProzessEventsActionType.UPDATE_COMPARISON_DATA, updateComparisonData);
}
