import { Dispatch } from "redux";
import { ConnectedDispatch, ConnectedState, OwnProps } from "./select-with-dialog.types";
import {
  givenDataApplied,
  prozessDataChanged,
  ProzessInputData,
} from "../../../store/prozess-events/prozess-events.actions";
import { AppState } from "../../../store/app.state";
import { connect } from "react-redux";
import SelectWithDialogComponent from "./select-with-dialog.component";
import {
  getCurrentRecordId,
  getGivenDataByWorkflowId,
} from "../../../store/prozess-events/prozess-events.selectors";
import {
  getIsManuallyEmpty,
  getShouldCreateProzessEventForMultipleProzess,
  isEditModeActivated,
} from "../../../store/common/common.selectors";
import { getEditedValueByWorkflowId } from "../../../store/captured-records/captured-records.selectors";

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => ({
  onChanged: (prozessInputData: ProzessInputData) => dispatch(prozessDataChanged(prozessInputData)),
  givenDataApplied: (workflowId: number) => dispatch(givenDataApplied(workflowId)),
});

const mapStateToProps = (state: AppState, ownProps: OwnProps): ConnectedState => {
  const workflowId = ownProps.prozess.workflowId!;

  return {
    givenData: getGivenDataByWorkflowId(workflowId)(state),
    prevRecordId: getCurrentRecordId(state),
    isManuallyEmpty: getIsManuallyEmpty(state),
    shouldCreateProzessEventForMultipleProzess: getShouldCreateProzessEventForMultipleProzess(state),
    editedValue: getEditedValueByWorkflowId(workflowId)(state),
    isEditing: isEditModeActivated(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectWithDialogComponent);
