import { FunctionComponent, useEffect, useCallback, useState } from "react";
import useStyles from "./select-with-date.styles";
import classnames from "classnames";
import { TextField } from "@mui/material";
import { Props, SelectWithDateState } from "./select-with-date.types";
import { useSelectWithDateState } from "./select-with-date.hooks";
import { convertDateForCalendar } from "../../../utils/datetime.utils";

const saveWhenRecordIsActive = (
  prevRecordId: string,
  recordId: string,
  save: (date: string | undefined) => void,
  state: SelectWithDateState,
  isEditing: boolean
) => {
  const { date, isProzessEdited } = state;
  const isRecordActive = prevRecordId === recordId;

  if (isEditing) {
    // Can be changed only if date doesn't exist for current sau.
    if (isRecordActive && isProzessEdited) {
      save(date);
    }
  } else {
    if (isRecordActive) {
      save(date);
    }
  }
};

const SelectWithDateComponent: FunctionComponent<Props> = props => {
  const {
    prozess,
    onChanged,
    recordId,
    shouldValidate,
    prevRecordId,
    editedValue,
    givenData,
    givenDataApplied,
    isManuallyEmpty,
    setIsValidationError,
    shouldCreateProzessEventForMultipleProzess,
    isEditing,
  } = props;
  const classes = useStyles();

  const [shouldDisable, setShouldDisable] = useState(false);

  const { state, init, reset, onProzessDataChanged, save, shouldEditProzessData } = useSelectWithDateState(
    prozess,
    onChanged,
    isEditing
  );

  const createProzessEventForMultipleProzess = useCallback(() => {
    // Fired if new prozess event for prozess which can create multiple prozess events was created in edit mode.
    if (shouldCreateProzessEventForMultipleProzess) {
      shouldEditProzessData(true);
    }
  }, [shouldCreateProzessEventForMultipleProzess, shouldEditProzessData]);

  const checkOnEditMode = useCallback(() => {
    if (isEditing && editedValue) {
      onProzessDataChanged(convertDateForCalendar(editedValue));
      if (!prozess.isEditable) {
        setShouldDisable(true);
      }
    } else {
      setShouldDisable(false);
      reset();
    }
  }, [editedValue, isEditing, onProzessDataChanged, prozess.isEditable, reset]);

  const validateStateError = useCallback(() => {
    // Say Funktion component, that prozess has a validation error in edit mode.
    if (state.isError) {
      setIsValidationError(true);
    } else {
      setIsValidationError(false);
    }
  }, [setIsValidationError, state.isError]);

  const checkOnGivenData = useCallback(() => {
    if (givenData) {
      if (givenData.data) {
        if (isEditing) {
          shouldEditProzessData(true);
        }
        // Check extractDataFromProzessEvent function in prozess-events.utils.ts to know what data can be here.
        if (typeof givenData.data === "object") {
          setShouldDisable(false);
          reset();
        } else {
          // Use date from additionalData if date was created ealier.
          const convertedDate = convertDateForCalendar(givenData.data);
          onProzessDataChanged(convertedDate);
          setShouldDisable(true);
        }
      } else {
        // If prozess with shouldListenForChangesOnWorkflowId was removed, remove date.
        reset();
        setShouldDisable(false);
      }
      givenDataApplied(prozess.workflowId!);
    }
  }, [
    givenData,
    givenDataApplied,
    prozess.workflowId,
    isEditing,
    shouldEditProzessData,
    reset,
    onProzessDataChanged,
  ]);

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    reset();
  }, [recordId, reset, isManuallyEmpty]);

  useEffect(() => {
    createProzessEventForMultipleProzess();
  }, [createProzessEventForMultipleProzess]);

  useEffect(() => {
    saveWhenRecordIsActive(prevRecordId, recordId, save, state, isEditing);
    // Should listen only state changes for creating a prozess event.
    // It should update perfomance and prevent strange errors.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    validateStateError();
  }, [validateStateError]);

  useEffect(() => {
    checkOnEditMode();
  }, [checkOnEditMode]);

  useEffect(() => {
    checkOnGivenData();
  }, [checkOnGivenData]);

  const manualChange = (manualValue: string) => {
    if (isEditing) {
      shouldEditProzessData(true);
    }
    onProzessDataChanged(manualValue);
  };

  return (
    <div style={{ gridArea: prozess.position }} data-cy="SelectWithDateComponent" className={classes.root}>
      <TextField
        id={prozess.prozessType}
        type="date"
        className={classnames(classes.textField, shouldDisable ? classes.disabledField : "")}
        label={prozess.label!}
        variant="outlined"
        value={state.date ? state.date : ""}
        required={prozess.isRequired}
        onChange={e => manualChange(e.currentTarget.value)}
        InputLabelProps={{ shrink: true }}
        error={shouldValidate && state.isError}
        disabled={shouldDisable}
      />
    </div>
  );
};

export default SelectWithDateComponent;
