import { takeLatest, call, take, put, cancelled, fork, race, delay } from "redux-saga/effects";
import BluetoothTerminal from "bluetooth-terminal";

import {
  BluetoothActionType,
  bluetoothDeviceError,
  bluetoothDeviceDisconnected,
  bluetoothDeviceConnecting,
  bluetoothDeviceConnected,
} from "./bluetooth.actions";

import logger from "../../logger";
import { eventChannel, EventChannel, channel, Channel } from "redux-saga";
import parseBluetoothData from "./bluetooth-data-parser";
import bluetoothDataSaga from "./bluetooth-data.saga";
import { Action } from "../action";

const logInfo = logger.info("bluetooth-connection.saga");
const logWarn = logger.warn("bluetooth-connection.saga");
const logError = logger.error("bluetooth-connection.saga");

let terminal: any;
let tryReconnect = true;
let connectedChannel: EventChannel<{}>;

/**
 * Erstellt eine neue Instanz vom Bluetooth-Terminal und setzt die Log-Methode
 */
export function initTerminal() {
  terminal = new BluetoothTerminal();

  terminal._log = (...messages: string[]) => {
    messages.forEach(message => {
      logInfo("bluetooth-terminal", message);
    });
  };
}

/**
 * Fügt einen Listener zum Bluetooth-Terminal hinzu, um auf ein disconnect zu reagieren
 */
export function* handleDisconnect() {
  // Event-Channel ist eine Brücke zwischen dem Callback- und dem Saga-Scope
  const disconnectedChannel = eventChannel(emitter => {
    const handleDisconnection = () => {
      emitter({});
    };

    terminal.setOnDisconnected(handleDisconnection);

    // Gibt eine Unsubscribe-Methode zurück
    return () => {
      terminal.setOnDisconnected(undefined);
    };
  });

  try {
    while (true) {
      yield take(disconnectedChannel);
      yield put(bluetoothDeviceConnecting());

      if (terminal.getDeviceName() && tryReconnect) {
        yield call(waitForReconnect);
      } else {
        yield put(bluetoothDeviceDisconnected());
      }
    }
  } catch (e) {
    logError("Unknown error occured", e);
  } finally {
    logInfo("disconnectedChannel terminated");
    // @ts-ignore
    if (yield cancelled()) {
      disconnectedChannel.close();
      yield put(bluetoothDeviceDisconnected());
      logInfo("disconnectedChannel cancelled");
    }
  }
}

/**
 * Wird aufgerufen, wenn das Terminal die Verbindung zum Bluetooth-Gerät verliert
 * und wartet eine bestimmte (konfigurierbare) Zeit auf ein Reconnect.
 * Wenn die Zeit abgelaufen ist und die Verbindung nicht wiederhergestellt wurde,
 * wird die Disconnect-Methode am Bluetooth-Terminal aufgerufen
 *
 * @returns true wenn der Reconnect erfolgreich war, andernfalls false
 */
function* waitForReconnect() {
  const maxTries = process.env.REACT_APP_RECONNECT_ATTEMPTS ? +process.env.REACT_APP_RECONNECT_ATTEMPTS : 5;
  let n = 0;
  while (n < maxTries) {
    const { actionReceived } = yield race({
      timeOut: delay(+process.env.REACT_APP_RECONNECT_DELAY!),
      actionReceived: take(BluetoothActionType.BLUETOOTH_DEVICE_CONNECTED),
    });

    n += 1;

    logInfo(n);

    if (actionReceived) {
      logInfo("Successfully reconnected");
      return true;
    } else {
      if (n < maxTries) {
        logWarn("Timeout during connection to device", terminal.getDeviceName());
      } else {
        logError("Max attempts to connect to device reached", terminal.getDeviceName());
        yield call(disconnect);
        return false;
      }
    }
  }
}

/**
 * Fügt dem Bluetooth-Terminal einen Ereignis-Listener hinzu, der auf eine Verbindung mit einem Bluetooth-Gerät wartet
 *
 * @returns true, wenn erfolgreich verbunden, andernfalls false
 */
export function* handleConnect() {
  try {
    // Event-Channel ist eine Brücke zwischen dem Callback- und dem Saga-Scope
    connectedChannel = eventChannel(emitter => {
      const handleConnection = () => {
        emitter({});
      };

      terminal.setOnConnected(handleConnection);

      // Gibt eine Unsubscribe-Methode zurück
      return () => {
        terminal.setOnConnected(undefined);
      };
    });

    while (true) {
      yield take(connectedChannel);
      yield put(bluetoothDeviceConnected(terminal.getDeviceName()));
    }
  } catch (e) {
    logError("Unknown error occured", e);
    return false;
  } finally {
    logInfo("connectedChannel terminated");
    // @ts-ignore
    if (yield cancelled()) {
      logInfo("connectedChannel cancelled");
    }
  }
}

/**
 * Initiiert eine Verbindung zu Bluetooth-Geräten
 */
export function* connect() {
  try {
    tryReconnect = true;
    yield put(bluetoothDeviceConnecting());
    yield call([terminal, "connect"]);
    logInfo("Successfully connected to device", terminal.getDeviceName());
  } catch (e) {
    logError("Could not connect to device", e);
    yield put(bluetoothDeviceError("Could not connect to device"));
    yield call([terminal, "disconnect"]);
  }
}

/**
 * Fügt dem Bluetooth-Terminal einen Ereignis-Listener hinzu, der auf neue Daten wartet,
 * die vom verbundenen Bluetooth-Gerät gesendet wurden
 */
export function* listenForData(dataChannel: Channel<any>) {
  // Event-Channel ist eine Brücke zwischen dem Callback- und dem Saga-Scope
  const valueChangedChannel = eventChannel(emitter => {
    const handleCharacteristicValueChanged = (data: string) => {
      emitter({ data });
    };

    terminal.receive = (data: string) => handleCharacteristicValueChanged(data);

    // Gibt eine Unsubscribe-Methode zurück
    return () => {
      terminal.receive = (data: string) => undefined;
    };
  });

  // Events verarbeiten bis der Vorgang abgeschlossen wurde
  try {
    while (true) {
      const { data } = yield take(valueChangedChannel);
      if (!data) {
        break;
      }

      try {
        const parsedData = parseBluetoothData(data);
        logInfo("Received data from bluetooth device", data, parsedData);
        yield put(dataChannel, parsedData);
      } catch (parseError) {
        logError("Die empfangenen Daten konnten nicht geparst werden", parseError);
      }
    }
  } catch (e) {
    logError("Error happened during stating notifications", e);
  } finally {
    logInfo("valueChangedChannel terminated");
    // @ts-ignore
    if (yield cancelled()) {
      valueChangedChannel.close();
      logInfo("valueChangedChannel cancelled");
    }
  }
}

/**
 * Trennt das aktuelle Bluetooth-Gerät
 */
export function* disconnect() {
  try {
    tryReconnect = false;
    yield call([terminal, "disconnect"]);
    logInfo("Successfully disconnected from device", terminal.getDeviceName());
    yield put(bluetoothDeviceDisconnected());
  } catch (e) {
    logError("Could not disconnect from device", e);
    yield put(bluetoothDeviceError("Could not disconnect from device"));
  }
}

export function* sendData(action: Action<string>) {
  try {
    yield call([terminal, "send"], action.payload);
    logInfo("Successfully send data from device", action.payload);
  } catch (e) {
    logError("Could not send data from device", e);
  }
}

/**
 * Übernimmt das herstellen/trennen einer Verbindung zu einem Bluetooth-Gerät und das weiterleiten
 * der empfangenen Daten von einem Bluetooth-Gerät an die Anwendung
 */
export default function* bluetoothConnectionSaga() {
  yield call(initTerminal);

  // Erzeugt einen Kanal um empfangene Daten an bluetoothDataSaga weiterzureichen
  // @ts-ignore
  const dataChannel = yield call(channel);
  yield fork(bluetoothDataSaga, dataChannel);
  yield fork(listenForData, dataChannel);

  yield fork(handleConnect);
  yield fork(handleDisconnect);

  yield takeLatest(BluetoothActionType.REQUEST_BLUETOOTH_DEVICE, connect);
  yield takeLatest(BluetoothActionType.DISCONNECT_BLUETOOTH_DEVICE, disconnect);
  yield takeLatest(BluetoothActionType.SEND_DATA, sendData);
}
