import { TransponderAction, TransponderActionType, TransponderState } from "./transponder.types";

import logger from "../../../logger";

const logInfo = logger.info("transponder.reducer");

export const initialState: TransponderState = {
  transponder: "",
  metaData: undefined,
  isError: false,
  isValid: false,
  isProzessEdited: undefined,
};

const isError = (isValid: boolean, isRequired?: boolean) => !!(!isValid && isRequired);

export const transponderReducer = (state: TransponderState, action: TransponderAction): TransponderState => {
  const {
    type,
    payload: { value, metaData, isRequired, shouldKeepValue, isValid, isProzessEdited },
  } = action;

  switch (type) {
    case TransponderActionType.INIT: {
      logInfo("TRANSPONDER INIT");
      return { ...initialState, isError: isError(!!isValid, isRequired) };
    }
    case TransponderActionType.PROZESS_DATA_CHANGED: {
      const transponder = value ? (value as string) : "";

      if (state.transponder === transponder && state.isError === isError(!!isValid, isRequired)) {
        return state;
      }

      return {
        ...state,
        transponder,
        metaData,
        isError: isError(!!isValid, isRequired),
        isValid: !!isValid,
      };
    }
    case TransponderActionType.RESET: {
      logInfo("TRANSPONDER RESET");
      const transponder = shouldKeepValue ? state.transponder : "";
      return { ...initialState, transponder, isError: isError(!!isValid, isRequired) };
    }
    case TransponderActionType.PROZESS_DATA_EDITED: {
      return { ...initialState, isProzessEdited };
    }
  }

  return state;
};
