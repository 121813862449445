import { v1 as uuidv1 } from "uuid";
import { IProzessEventsWithAdditionalData } from "../../pages/funktion/funktion.types";
import createReducer from "../createReducer";
import { ProzessEventsActionType, ProzessInputData } from "./prozess-events.actions";
import { Action } from "../action";
import { LoadingStatus, loadInitial, loadPending, loadFailed } from "../loading-status";

export interface ProzessEventsRecord {
  [workflowId: string]: any; // IProzessEventsWithAdditionalData | IProzessEventsWithAdditionalData[];
}

export interface ProzessEventsState {
  recordId: string;
  recordsGroupId: string;
  prozessEvents: ProzessEventsRecord;
  allProzessEventsValidInCreationMode: boolean | undefined;
  givenData: { [workflowId: string]: ProzessInputData };
  filteredBy: { [workflowId: string]: ProzessInputData };
  comparisonData: { [workflowId: string]: ProzessInputData };
  appVersion: string;
  synchronousProcessingStatus: LoadingStatus;
  recordProcessingStatus: LoadingStatus;
}

export const createInitialState = (): ProzessEventsState => ({
  recordId: uuidv1(),
  recordsGroupId: uuidv1(),
  prozessEvents: {},
  allProzessEventsValidInCreationMode: undefined,
  givenData: {},
  filteredBy: {},
  comparisonData: {},
  appVersion: process.env.REACT_APP_APP_VERSION!,
  synchronousProcessingStatus: loadInitial(),
  recordProcessingStatus: loadInitial(),
});

export default createReducer(createInitialState(), {
  [ProzessEventsActionType.CREATE_NEW_RECORD]() {
    return createInitialState();
  },
  [ProzessEventsActionType.SET_PROZESS_EVENT](
    state: ProzessEventsState,
    action: Action<IProzessEventsWithAdditionalData>
  ): ProzessEventsState {
    const prozessEvent = action.payload;
    const workflowId = prozessEvent.workflowId;
    return {
      ...state,
      prozessEvents: { ...state.prozessEvents, [workflowId]: prozessEvent },
      allProzessEventsValidInCreationMode: undefined,
    };
  },
  [ProzessEventsActionType.SET_MULTIPLE_PROZESS_EVENT](
    state: ProzessEventsState,
    action: Action<IProzessEventsWithAdditionalData>
  ): ProzessEventsState {
    const prozessEvent = action.payload;
    const workflowId = prozessEvent.workflowId;
    if (!state.prozessEvents[workflowId]) {
      return {
        ...state,
        prozessEvents: {
          ...state.prozessEvents,
          [workflowId]: [prozessEvent],
        },
        allProzessEventsValidInCreationMode: undefined,
      };
    }
    return {
      ...state,
      prozessEvents: {
        ...state.prozessEvents,
        [workflowId]: [...state.prozessEvents[workflowId], prozessEvent],
      },
      allProzessEventsValidInCreationMode: undefined,
    };
  },
  [ProzessEventsActionType.DELETE_PROZESS_EVENT](
    state: ProzessEventsState,
    action: Action<number>
  ): ProzessEventsState {
    const workflowId = action.payload;

    if (state.prozessEvents[workflowId]) {
      const prozessEvents = { ...state.prozessEvents };
      delete prozessEvents[workflowId];
      return {
        ...state,
        prozessEvents,
        allProzessEventsValidInCreationMode: undefined,
      };
    }
    return state;
  },
  [ProzessEventsActionType.DELETE_MULTIPLE_PROZESS_EVENT](
    state: ProzessEventsState,
    action: Action<ProzessInputData>
  ): ProzessEventsState {
    const { workflowId, data } = action.payload;

    if ((state.prozessEvents[workflowId] && state.prozessEvents[workflowId].length === 1) || !data) {
      const prozessEvents = { ...state.prozessEvents };
      delete prozessEvents[workflowId];
      return {
        ...state,
        prozessEvents,
        allProzessEventsValidInCreationMode: undefined,
      };
    }

    return {
      ...state,
      prozessEvents: {
        ...state.prozessEvents,
        [workflowId]: state.prozessEvents[workflowId].filter(
          (item: IProzessEventsWithAdditionalData) => item.data !== data
        ),
      },
    };
  },
  [ProzessEventsActionType.CLEAR_PROZESS_EVENTS](
    state: ProzessEventsState,
    action: Action<null>
  ): ProzessEventsState {
    return {
      ...state,
      prozessEvents: {},
      allProzessEventsValidInCreationMode: undefined,
      recordProcessingStatus: loadInitial(),
    };
  },
  [ProzessEventsActionType.PROZESS_EVENTS_ARE_VALID](
    state: ProzessEventsState,
    action: Action<null>
  ): ProzessEventsState {
    return { ...state, allProzessEventsValidInCreationMode: true };
  },
  [ProzessEventsActionType.PROZESS_EVENTS_NOT_VALID](
    state: ProzessEventsState,
    action: Action<null>
  ): ProzessEventsState {
    return {
      ...state,
      allProzessEventsValidInCreationMode: false,
      recordProcessingStatus: loadFailed("Prozess events are not valid"),
    };
  },
  [ProzessEventsActionType.SET_GIVEN_DATA](
    state: ProzessEventsState,
    action: Action<IProzessEventsWithAdditionalData>
  ): ProzessEventsState {
    const givenData = action.payload;
    const workflowId = givenData.workflowId;
    return {
      ...state,
      givenData: { ...state.givenData, [workflowId]: givenData },
    };
  },

  [ProzessEventsActionType.GIVEN_DATA_APPLIED](
    state: ProzessEventsState,
    action: Action<number>
  ): ProzessEventsState {
    const workflowId = action.payload;

    if (state.givenData[workflowId]) {
      const givenData = { ...state.givenData };
      delete givenData[workflowId];
      return {
        ...state,
        givenData,
      };
    }
    return state;
  },
  [ProzessEventsActionType.SET_FILTERED_BY](
    state: ProzessEventsState,
    action: Action<IProzessEventsWithAdditionalData>
  ): ProzessEventsState {
    const filteredBy = action.payload;
    const workflowId = filteredBy.workflowId;
    return {
      ...state,
      filteredBy: { ...state.filteredBy, [workflowId]: filteredBy },
    };
  },
  [ProzessEventsActionType.DELETE_FILTERED_BY](
    state: ProzessEventsState,
    action: Action<number>
  ): ProzessEventsState {
    const workflowId = action.payload;

    if (state.filteredBy[workflowId]) {
      const filteredBy = { ...state.filteredBy };
      delete filteredBy[workflowId];
      return {
        ...state,
        filteredBy,
      };
    }
    return state;
  },
  [ProzessEventsActionType.SET_COMPARISON_DATA](
    state: ProzessEventsState,
    action: Action<IProzessEventsWithAdditionalData>
  ): ProzessEventsState {
    const comparisonData = action.payload;
    const workflowId = comparisonData.workflowId;
    return {
      ...state,
      comparisonData: { ...state.comparisonData, [workflowId]: comparisonData },
    };
  },
  [ProzessEventsActionType.DELETE_COMPARISON_DATA](
    state: ProzessEventsState,
    action: Action<number>
  ): ProzessEventsState {
    const workflowId = action.payload;

    if (state.comparisonData[workflowId]) {
      const comparisonData = { ...state.comparisonData };
      delete comparisonData[workflowId];
      return {
        ...state,
        comparisonData,
      };
    }
    return state;
  },
  [ProzessEventsActionType.START_SYNCHRONOUS_PROCESSING](state: ProzessEventsState, action: Action<null>) {
    return {
      ...state,
      synchronousProcessingStatus: loadPending(),
    };
  },
  [ProzessEventsActionType.FINISH_SYNCHRONOUS_PROCESSING](state: ProzessEventsState, action: Action<null>) {
    return {
      ...state,
      synchronousProcessingStatus: loadFailed("An unexpected network error has occurred"),
    };
  },
  [ProzessEventsActionType.SAVE_PROZESS_EVENTS](state: ProzessEventsState, action: Action<null>) {
    return {
      ...state,
      recordProcessingStatus: loadPending(),
    };
  },
});
