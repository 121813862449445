import { Button, CircularProgress, IconButton, InputAdornment, TextField } from "@mui/material";

import { CheckCircle, Replay } from "@mui/icons-material";
import { FunctionComponent, useCallback, useEffect, useState, useMemo } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import useStyles from "./gewicht.styles";
import { useGewichtState } from "./gewicht.hooks";
import { GewichtProps, GewichtConfiguration } from "./gewicht.types";
import { BluetoothDataFormat } from "../../../store/bluetooth/bluetooth-data-format";

const saveWhenRecordIsActiveAndGewichtFixed = (
  prevRecordId: string,
  recordId: string,
  save: (gewicht: string | undefined, metaData?: BluetoothDataFormat) => void,
  gewicht: string | undefined,
  metaData: BluetoothDataFormat | undefined,
  isStable: boolean,
  manualInput: boolean,
  isProzessEdited: boolean | undefined,
  isEditing: boolean,
  shouldUseManualGewichtValue: boolean
) => {
  const isRecordActive = prevRecordId === recordId;
  const isGewichtFix = isStable || manualInput || shouldUseManualGewichtValue;

  if (isEditing) {
    if (isRecordActive && isGewichtFix && isProzessEdited) {
      save(gewicht, metaData);
    }
  } else {
    if (isRecordActive && isGewichtFix) {
      save(gewicht, metaData);
    }
  }
};

const GewichtComponent: FunctionComponent<GewichtProps> = props => {
  const {
    stableGewicht,
    lastReceivedGewicht,
    restartStableGewicht,
    prozess,
    onChanged,
    recordId,
    manualInput,
    shouldValidate,
    prevRecordId,
    editedValue,
    setIsValidationError,
    sendTareData,
    isManuallyEmpty,
    stableGewichtCaptured,
    shouldUseManualGewichtValue,
    shouldCreateProzessEventForMultipleProzess,
    isWaageMode,
    shouldCalculateDifference,
    activateDifferentialWeighting,
    isEditing,
  } = props;
  const {
    validMinValue,
    validMaxValue,
    optimalMinValue,
    optimalMaxValue,
    gewichtMaxDifference,
    gewichtAverageCount,
    skipNumberOfValidGewichtValues,
  } = prozess;

  const gewichtConfiguration: GewichtConfiguration = useMemo(
    () => ({
      validMinValue,
      validMaxValue,
      optimalMinValue,
      optimalMaxValue,
      gewichtMaxDifference,
      gewichtAverageCount,
      skipNumberOfValidGewichtValues,
    }),
    [
      gewichtAverageCount,
      gewichtMaxDifference,
      optimalMaxValue,
      optimalMinValue,
      skipNumberOfValidGewichtValues,
      validMaxValue,
      validMinValue,
    ]
  );

  const classes = useStyles();
  const { t } = useTranslation();

  const { init, onProzessDataChanged, save, reset, state, shouldEditProzessData } = useGewichtState(
    gewichtConfiguration,
    restartStableGewicht,
    manualInput,
    prozess,
    onChanged,
    isEditing,
    shouldCalculateDifference
  );

  const [shouldDisable, setShouldDisable] = useState(false);
  const [isTare, setIsTare] = useState(false);

  const isStable = useMemo(() => stableGewicht !== undefined, [stableGewicht]);
  const receivedGewicht = useMemo(
    () => (isStable ? stableGewicht : lastReceivedGewicht),
    [isStable, lastReceivedGewicht, stableGewicht]
  );
  const showCircularProgress = useMemo(
    () => !isStable && !manualInput && !shouldUseManualGewichtValue,
    [isStable, manualInput, shouldUseManualGewichtValue]
  );
  const showCheckCircle = useMemo(
    () => !showCircularProgress && (state.isOptimal || state.isValid) && !shouldCalculateDifference,
    [shouldCalculateDifference, showCircularProgress, state.isOptimal, state.isValid]
  );

  const evaluateClassName = useCallback(
    (optimalClassName: string, validClassName: string) =>
      isStable || manualInput || shouldUseManualGewichtValue
        ? state.isOptimal
          ? optimalClassName
          : state.isValid
          ? validClassName
          : ""
        : "",
    [isStable, manualInput, shouldUseManualGewichtValue, state.isOptimal, state.isValid]
  );
  const textfieldClassName = evaluateClassName(classes.optimalLabel, classes.validLabel);
  const outlineClassName = evaluateClassName(classes.optimalBorder, classes.validBorder);

  const createProzessEventForMultipleProzess = useCallback(() => {
    // Fired if new prozess event for prozess which can create multiple prozess events was created in edit mode.
    if (shouldCreateProzessEventForMultipleProzess) {
      shouldEditProzessData(true);
    }
  }, [shouldCreateProzessEventForMultipleProzess, shouldEditProzessData]);

  const checkOnEditMode = useCallback(() => {
    if (isEditing && editedValue) {
      shouldEditProzessData(false);
      onProzessDataChanged(editedValue, true);
      // Set only value for displaying in edit mode.
      stableGewichtCaptured({ value: editedValue } as BluetoothDataFormat);
      if (!prozess.isEditable) {
        setShouldDisable(true);
      }
    } else {
      reset();
      setShouldDisable(false);
    }
  }, [
    isEditing,
    editedValue,
    shouldEditProzessData,
    onProzessDataChanged,
    stableGewichtCaptured,
    prozess.isEditable,
    reset,
  ]);

  const validateStateError = useCallback(() => {
    // Say Funktion component, that prozess has a validation error in edit mode.
    if (state.isError || (!isStable && !manualInput)) {
      setIsValidationError(true);
    } else {
      setIsValidationError(false);
    }
  }, [isStable, manualInput, setIsValidationError, state.isError]);

  useEffect(() => {
    createProzessEventForMultipleProzess();
  }, [createProzessEventForMultipleProzess]);

  useEffect(() => {
    saveWhenRecordIsActiveAndGewichtFixed(
      prevRecordId,
      recordId,
      save,
      state.gewicht,
      state.metaData,
      state.isStable,
      manualInput,
      state.isProzessEdited,
      isEditing,
      shouldUseManualGewichtValue
    );
  }, [isEditing, manualInput, prevRecordId, recordId, save, shouldUseManualGewichtValue, state]);

  useEffect(() => {
    init();
  }, [init, prozess]);

  useEffect(() => {
    reset();
  }, [reset, recordId, isManuallyEmpty, manualInput, shouldUseManualGewichtValue]);

  useEffect(() => {
    if (!shouldUseManualGewichtValue) {
      // Set stable gewicht value only if automatisch (BT) is active.
      onProzessDataChanged(stableGewicht?.value, !!stableGewicht, stableGewicht);
    }
  }, [onProzessDataChanged, shouldUseManualGewichtValue, stableGewicht]);

  useEffect(() => {
    if (!isStable && !shouldUseManualGewichtValue) {
      // Set intermediate received values only if automatisch (BT) is active.
      onProzessDataChanged(receivedGewicht?.value, false, receivedGewicht);
    }
  }, [onProzessDataChanged, isStable, receivedGewicht, shouldUseManualGewichtValue]);

  useEffect(() => {
    if (isWaageMode) {
      // Set stable gewicht value only if automatisch (BT) is active.
      onProzessDataChanged(stableGewicht?.value, !!stableGewicht, stableGewicht);
    }
  }, [onProzessDataChanged, isWaageMode, stableGewicht]);

  useEffect(() => {
    if (!isStable && isWaageMode) {
      // Set intermediate received values only if automatisch (BT) is active.
      onProzessDataChanged(receivedGewicht?.value, false, receivedGewicht);
    }
  }, [onProzessDataChanged, isStable, receivedGewicht, isWaageMode]);

  useEffect(() => {
    checkOnEditMode();
  }, [checkOnEditMode]);

  useEffect(() => {
    validateStateError();
  }, [validateStateError]);

  useEffect(() => {
    if (shouldCalculateDifference) {
      activateDifferentialWeighting(true);
    }
  }, [shouldCalculateDifference, activateDifferentialWeighting]);

  useEffect(
    () =>
      // Stop using differential weighting if you are leave funktion.
      () =>
        activateDifferentialWeighting(false),
    [activateDifferentialWeighting]
  );

  const manualChange = (manualValue: string) => {
    shouldEditProzessData(true);
    onProzessDataChanged(manualValue, false);
  };

  const restartStableGewichtHandler = useCallback(() => {
    shouldEditProzessData(true);
    restartStableGewicht(gewichtConfiguration);
  }, [gewichtConfiguration, restartStableGewicht, shouldEditProzessData]);

  const sendTareDataHandler = useCallback(() => {
    sendTareData("tare");
    setIsTare(true);

    setTimeout(() => {
      setIsTare(false);
    }, 3000);

    restartStableGewichtHandler();
  }, [restartStableGewichtHandler, sendTareData]);

  const revealDisplayValue = useCallback(() => {
    if (isTare) {
      return t("COMMON.TARA").toUpperCase();
    }
    if (!state.gewicht && !manualInput && !shouldUseManualGewichtValue) {
      return "0";
    }
    return state.gewicht;
  }, [isTare, manualInput, shouldUseManualGewichtValue, state.gewicht, t]);

  return (
    <div style={{ gridArea: prozess.position }} className={classes.root}>
      <TextField
        id={prozess.prozessType}
        required={prozess.isRequired}
        label={prozess.label!}
        variant="outlined"
        type={isTare ? "text" : "number"}
        value={revealDisplayValue()}
        disabled={shouldDisable}
        className={classNames(
          classes.textField,
          classes.inputMinWidth,
          !isWaageMode && !shouldCalculateDifference ? textfieldClassName : ""
        )}
        // This callback is only called on manual input
        onChange={e => manualChange(e.currentTarget.value)}
        InputProps={{
          readOnly: isWaageMode || (!manualInput && !shouldUseManualGewichtValue),
          classes: {
            adornedEnd: classes.adornedEnd,
            notchedOutline:
              !isWaageMode && !shouldDisable && !shouldCalculateDifference ? outlineClassName : "",
            root: isWaageMode ? classes.waageInput : "",
          },
          endAdornment: (
            <>
              {showCheckCircle && (
                <InputAdornment position="end" className={classes.checkCircleAdornment}>
                  <IconButton className={shouldDisable ? classes.disabledCheckCircle : classes.checkCircle}>
                    <CheckCircle
                      className={isWaageMode ? classes.waageAdornment : ""}
                      data-icon="CheckCircle"
                    />
                  </IconButton>
                </InputAdornment>
              )}
              {showCircularProgress && (
                <InputAdornment position="end" className={classes.circularProgressAdornment}>
                  <CircularProgress className={isWaageMode ? classes.waageAdornment : ""} size={20} />
                </InputAdornment>
              )}
            </>
          ),
        }}
        InputLabelProps={{ shrink: true }}
        error={
          shouldValidate &&
          (!manualInput && !shouldUseManualGewichtValue ? !isStable && state.isError : state.isError)
        }
      />
      <Button
        variant="contained"
        size="large"
        onClick={restartStableGewichtHandler}
        className={classes.touchIconButton}
        disabled={!isStable || manualInput || shouldUseManualGewichtValue}
      >
        <Replay />
      </Button>
      <Button
        variant="contained"
        size="large"
        onClick={sendTareDataHandler}
        className={classes.touchIconButton}
        disabled={manualInput || shouldUseManualGewichtValue}
      >
        {t("COMMON.TARA").toUpperCase()}
      </Button>
    </div>
  );
};

export default GewichtComponent;
