export enum LocalStorageItems {
  DEBUG = "debug",
  LOGGED_IN_USER = "loggedInUser",
  TOKEN = "token",
  REFRESH_TOKEN = "refreshToken",
  DEVICE_ID = "deviceId",
  USER_ID = "userId",
  USE_BLUETOOTH_SIMULATION = "useBluetoothSimulation",
  LAST_SYNC = "lastSync",
  TRY_TO_SYNC = "tryToSync",
  LANGUAGE = "language",
}
