import { Action } from "../action";
import createReducer from "../createReducer";
import { CommonActionType } from "./common.actions";

export interface CommonState {
  drawerOpen: boolean;
  isAppUpdateAvailable: boolean;
  isEditModeActivated: boolean;
  shouldHighlightRecord: boolean;
  isManuallyEmpty: boolean;
  isDialogOpen: boolean;
  shouldCreateProzessEventForMultipleProzess: boolean;
  countOfMultipleProzessEvents: number | undefined;
  isDifferentialWeightingActive: boolean;
  forceAppUpdate: boolean;
  isAppDeprecated: boolean;
  isDataImportSnackbarOpen: boolean;
  shouldOpenValidationOverviewDialog: boolean;
}

export const initialState: CommonState = {
  drawerOpen: false,
  isAppUpdateAvailable: false,
  isEditModeActivated: false,
  shouldHighlightRecord: false,
  isManuallyEmpty: false,
  isDialogOpen: false,
  shouldCreateProzessEventForMultipleProzess: false,
  countOfMultipleProzessEvents: undefined,
  isDifferentialWeightingActive: false,
  forceAppUpdate: false,
  isAppDeprecated: false,
  isDataImportSnackbarOpen: false,
  shouldOpenValidationOverviewDialog: false,
};

export default createReducer(initialState, {
  [CommonActionType.TOGGLE_DRAWER](state: CommonState, action: Action<boolean>) {
    return { ...state, drawerOpen: action.payload };
  },
  [CommonActionType.IS_APP_UPDATE_AVAILABLE](state: CommonState, action: Action<boolean>) {
    return { ...state, isAppUpdateAvailable: action.payload };
  },
  [CommonActionType.TOGGLE_EDIT_MODE](state: CommonState, action: Action<boolean>) {
    return { ...state, isEditModeActivated: action.payload };
  },
  [CommonActionType.HIGHLIGHT_RECORD](state: CommonState, action: Action<boolean>) {
    return { ...state, shouldHighlightRecord: action.payload };
  },
  [CommonActionType.IS_MANUALLY_EMPTY](state: CommonState, action: Action<boolean>) {
    return { ...state, isManuallyEmpty: action.payload };
  },
  [CommonActionType.SET_IS_DIALOG_OPEN](state: CommonState, action: Action<boolean>) {
    return { ...state, isDialogOpen: action.payload };
  },
  [CommonActionType.SHOULD_CREATE_PROZESS_EVENT_FOR_MULTIPLE_PROZESS](
    state: CommonState,
    action: Action<boolean>
  ) {
    return { ...state, shouldCreateProzessEventForMultipleProzess: action.payload };
  },
  [CommonActionType.SET_COUNT_OF_MULTIPLE_PROZESS_EVENTS](
    state: CommonState,
    action: Action<number | undefined>
  ) {
    return { ...state, countOfMultipleProzessEvents: action.payload };
  },
  [CommonActionType.USE_DIFFERENTIAL_WEIGHTING](state: CommonState, action: Action<boolean>) {
    return { ...state, isDifferentialWeightingActive: action.payload };
  },
  [CommonActionType.FORCE_USER_TO_UPDATE](state: CommonState, action: Action<boolean>) {
    return { ...state, forceAppUpdate: action.payload };
  },
  [CommonActionType.IS_APP_DEPRECATED](state: CommonState, action: Action<boolean>) {
    return { ...state, isAppDeprecated: action.payload };
  },
  [CommonActionType.TOGGLE_DATA_IMPORT_SNACKBAR](state: CommonState, action: Action<boolean>) {
    return { ...state, isDataImportSnackbarOpen: action.payload };
  },
  [CommonActionType.SET_SHOULD_OPEN_VALIDATION_OVERVIEW_DIALOG](state: CommonState, action: Action<boolean>) {
    return { ...state, shouldOpenValidationOverviewDialog: action.payload };
  },
});
