import { TextField } from "@mui/material";
import { FunctionComponent, useEffect, useCallback, useState } from "react";
import WarningModalWindow from "../../modal-windows/warning-dialog/warning-dialog.component";
import classNames from "classnames";
import useStyles from "./transponder.styles";
import { Props } from "./transponder.types";
import { useTransponderState } from "./transponder.hooks";
import { defineFerkelProperty, findFerkelByQuery } from "../../../utils/ferkel.utils";

import logger from "../../../logger";
import { BluetoothDataFormat } from "../../../store/bluetooth/bluetooth-data-format";

const logInfo = logger.info("transponder.component");

const saveWhenRecordIsActive = (
  prevRecordId: string,
  recordId: string,
  save: (tierIdent: string | undefined, metaData?: BluetoothDataFormat) => void,
  transponder: string | undefined,
  metaData: BluetoothDataFormat | undefined,
  isProzessEdited: boolean | undefined,
  isEditing: boolean
) => {
  const isRecordActive = prevRecordId === recordId;

  if (isEditing) {
    if (isRecordActive && isProzessEdited) {
      save(transponder, metaData);
    }
  } else {
    if (isRecordActive) {
      save(transponder, metaData);
    }
  }
};

export const TRANSPONDER_VALID_LENGTH = 15;

const TransponderComponent: FunctionComponent<Props> = props => {
  const {
    transponder,
    prozess,
    recordId,
    manualInput,
    shouldValidate,
    onChanged,
    prevRecordId,
    editedValue,
    setIsValidationError,
    isManuallyEmpty,
    shouldIgnoreBluetoothData,
    shouldCreateProzessEventForMultipleProzess,
    isEditing,
    ferkel,
    saveRecord,
    givenData,
    givenDataApplied,
  } = props;
  const [shouldShowWarning, setShouldShowWarning] = useState(false);
  const classes = useStyles();

  const { init, onProzessDataChanged, save, reset, state, shouldEditProzessData } = useTransponderState(
    prozess,
    onChanged,
    manualInput,
    isEditing,
    ferkel
  );

  const createProzessEventForMultipleProzess = useCallback(() => {
    // Fired if new prozess event for prozess which can create multiple prozess events was created in edit mode.
    if (shouldCreateProzessEventForMultipleProzess) {
      shouldEditProzessData(true);
    }
  }, [shouldCreateProzessEventForMultipleProzess, shouldEditProzessData]);

  const checkOnEditMode = useCallback(() => {
    if (isEditing && editedValue) {
      shouldEditProzessData(false);
      onProzessDataChanged(editedValue);
    } else {
      shouldEditProzessData(false);
      onProzessDataChanged("");
    }
  }, [editedValue, isEditing, onProzessDataChanged, shouldEditProzessData]);

  const validateStateError = useCallback(() => {
    // Say Funktion component, that prozess has a validation error in edit mode.
    if (state.isError) {
      setIsValidationError(true);
    } else {
      setIsValidationError(false);
    }
  }, [setIsValidationError, state.isError]);

  const isTransponderAlreadyExist = useCallback(
    async (value: string) => !!(await findFerkelByQuery("transponder", Number(value))),
    []
  );

  const checkIsTransponderAlreadyExist = useCallback(async () => {
    if (
      state.transponder?.toString().length === TRANSPONDER_VALID_LENGTH &&
      state.transponder !== editedValue &&
      !prozess.shouldAllowMultipleUsage
    ) {
      const isExist = await isTransponderAlreadyExist(state.transponder);
      if (isExist) {
        setShouldShowWarning(true);
      }
    }
    // Listen for changes only on state.transponder.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTransponderAlreadyExist, state.transponder]);

  const changeValueViaBluetooth = useCallback(() => {
    if (!shouldIgnoreBluetoothData && transponder && !prozess.hasToIgnoreBluetoothData) {
      if (isEditing) {
        shouldEditProzessData(true);
      }
      if (prozess.shouldAutoSaveOnScan) {
        if (state.transponder && state.transponder !== transponder.value) {
          saveRecord();
          logInfo("The record was saved automatically");

          setTimeout(() => {
            onProzessDataChanged(transponder.value, transponder);
            logInfo(`New transponder ${transponder.value} was set after auto saving`);
          }, 1000);
        } else {
          onProzessDataChanged(transponder.value, transponder);
        }
      } else {
        onProzessDataChanged(transponder.value, transponder);
        logInfo(`Transponder has changed without auto saving mode to ${transponder.value}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transponder, isEditing]);

  const checkOnGivenData = useCallback(async () => {
    if (givenData) {
      if (givenData.data) {
        const ferkelKey = defineFerkelProperty(givenData.data.prozessType);
        const ferkel = await findFerkelByQuery(ferkelKey, givenData.data.data);
        if (ferkel) {
          onProzessDataChanged(ferkel.transponder?.toString());
        }
      } else {
        reset();
      }
    }
    givenDataApplied(prozess.workflowId!);
  }, [givenData, givenDataApplied, onProzessDataChanged, prozess.workflowId, reset]);

  useEffect(() => {
    init();
  }, [init, prozess]);

  useEffect(() => {
    reset();
  }, [recordId, reset, manualInput, isManuallyEmpty]);

  useEffect(() => {
    createProzessEventForMultipleProzess();
  }, [createProzessEventForMultipleProzess]);

  useEffect(() => {
    saveWhenRecordIsActive(
      prevRecordId,
      recordId,
      save,
      state.transponder,
      state.metaData,
      state.isProzessEdited,
      isEditing
    );
    // Should listen only state changes for creating a prozess event.
    // It should update perfomance and prevent strange errors.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.transponder]);

  useEffect(() => {
    checkOnEditMode();
  }, [checkOnEditMode]);

  useEffect(() => {
    changeValueViaBluetooth();
  }, [changeValueViaBluetooth]);

  useEffect(() => {
    checkIsTransponderAlreadyExist();
  }, [checkIsTransponderAlreadyExist]);

  useEffect(() => {
    validateStateError();
  }, [validateStateError]);
  useEffect(() => {
    checkOnGivenData();
  }, [checkOnGivenData]);

  const manualChange = (manualValue: string) => {
    shouldEditProzessData(true);
    onProzessDataChanged(manualValue);
  };

  const acceptModalHandler = () => {
    setShouldShowWarning(false);
    reset();
  };

  return (
    <div style={{ gridArea: prozess.position }} className={classes.root}>
      <TextField
        id={prozess.prozessType}
        required={prozess.isRequired}
        label={prozess.label!}
        variant="outlined"
        type="number"
        value={state.transponder}
        className={classNames(classes.textField, state.isValid ? classes.validLabel : "")}
        onChange={e => manualChange(e.currentTarget.value)}
        InputProps={{
          readOnly: !manualInput || prozess.isReadOnly,
          classes: {
            notchedOutline: state.isValid ? classes.validBorder : "",
          },
        }}
        InputLabelProps={{ shrink: true }}
        inputProps={{ min: 0 }}
        error={shouldValidate && state.isError}
      />
      {shouldShowWarning && !prozess.isReadOnly && (
        <WarningModalWindow
          open={shouldShowWarning}
          acceptHandler={acceptModalHandler}
          title={`Bekannter ${prozess.label}`}
        >
          Zu dieser Transpondernummer existiert bereits ein Datensatz. Eine Transpondernummer kann nicht
          doppelt verwendet werden. Verwenden Sie eine andere Transpondernummer.
        </WarningModalWindow>
      )}
    </div>
  );
};

export default TransponderComponent;
