import { IProzessDto } from "../../../api/backend-api-v7";
import {
  ProzessEventOperationTypes,
  ProzessInputData,
} from "../../../store/prozess-events/prozess-events.actions";
import { initialState, tierIdentReducer } from "./tierident.reducer";
import { useCallback, useReducer } from "react";
import { TierIdentActionType } from "./tierident.types";
import { BluetoothDataFormat } from "../../../store/bluetooth/bluetooth-data-format";

export const useTierIdentState = (
  prozess: IProzessDto,
  onChanged: (prozessInputData: ProzessInputData) => void,
  valueWillBeGenerated: boolean,
  manualInput: boolean,
  isEditing: boolean
) => {
  const [state, dispatch] = useReducer(tierIdentReducer, initialState);
  const { isRequired, shouldKeepValue } = prozess;

  const isTierIdentValid = (tierIdent: string | undefined) => !!tierIdent;

  const init = useCallback(
    () =>
      dispatch({
        type: TierIdentActionType.INIT,
        payload: { value: "", isRequired, shouldKeepValue, status: ProzessEventOperationTypes.DELETE },
      }),
    [isRequired, shouldKeepValue]
  );

  const onProzessDataChanged = useCallback(
    (value: string | undefined) => {
      dispatch({
        type: TierIdentActionType.PROZESS_DATA_CHANGED,
        payload: {
          value,
          isRequired,
          isValid: isTierIdentValid(value),
          shouldKeepValue,
          status: ProzessEventOperationTypes.CREATE,
        },
      });
    },
    [isRequired, shouldKeepValue]
  );

  const reset = useCallback(
    () =>
      dispatch({
        type: TierIdentActionType.RESET,
        payload: { value: "", isRequired, shouldKeepValue, status: ProzessEventOperationTypes.DELETE },
      }),
    [isRequired, shouldKeepValue]
  );

  const revealEventType = useCallback(
    () =>
      isEditing && manualInput
        ? prozess.changeManualEventType
        : isEditing && !manualInput
        ? prozess.changeEventType
        : !isEditing && manualInput
        ? prozess.manualEventType
        : prozess.eventType,
    [
      isEditing,
      manualInput,
      prozess.changeEventType,
      prozess.changeManualEventType,
      prozess.eventType,
      prozess.manualEventType,
    ]
  );

  const save = useCallback(
    (tierIdent: string | undefined, metaData?: BluetoothDataFormat) => {
      onChanged({
        workflowId: prozess.workflowId,
        eventType: revealEventType(),
        eventCategory: prozess.eventCategory,
        data: tierIdent,
        metaData,
        additionalData: undefined,
        operation: isEditing
          ? ProzessEventOperationTypes.CREATE
          : tierIdent
          ? ProzessEventOperationTypes.CREATE
          : ProzessEventOperationTypes.DELETE,
        isValid: prozess.isRequired ? !!tierIdent : true,
        prozessType: prozess.prozessType,
      } as ProzessInputData);
    },
    [
      onChanged,
      prozess.workflowId,
      prozess.eventCategory,
      prozess.isRequired,
      prozess.prozessType,
      revealEventType,
      isEditing,
    ]
  );

  const shouldEditProzessData = useCallback(
    // toggle this action if you need to create new prozess in edit mode.
    (isProzessEdited: boolean) =>
      dispatch({
        type: TierIdentActionType.PROZESS_DATA_EDITED,
        payload: {
          value: "",
          isRequired,
          shouldKeepValue,
          status: ProzessEventOperationTypes.EDIT,
          isProzessEdited,
        },
      }),
    [isRequired, shouldKeepValue]
  );

  return { state, init, reset, onProzessDataChanged, save, shouldEditProzessData };
};
