import { useCallback, useReducer } from "react";
import { keyValueInfoTableReducer, initialState } from "./key-value-table.reducer";
import { KeyValueInfoTableActionType } from "./key-value-table.types";

import { IProzessDto, KeyValueDto } from "../../../api/backend-api-v7";
import {
  ProzessEventOperationTypes,
  ProzessInputData,
} from "../../../store/prozess-events/prozess-events.actions";

export const useKeyValueInfoTableState = (
  prozess: IProzessDto,
  onChanged: (prozessInputData: ProzessInputData) => void,
  isEditing: boolean
) => {
  const [state, dispatch] = useReducer(keyValueInfoTableReducer, initialState);
  const { isRequired } = prozess;

  const onProzessDataChanged = useCallback(
    (value: KeyValueDto[] | undefined) => {
      dispatch({
        type: KeyValueInfoTableActionType.PROZESS_DATA_CHANGED,
        payload: {
          value,
          isRequired,
          isValid: !!value?.length,
          status: ProzessEventOperationTypes.CREATE,
        },
      });
    },
    [isRequired]
  );

  const save = useCallback(
    (value: KeyValueDto[] | undefined) => {
      onChanged({
        workflowId: prozess.workflowId,
        eventType: isEditing ? prozess.changeEventType : prozess.eventType,
        eventCategory: prozess.eventCategory,
        data: value,
        label: undefined,
        metaData: undefined,
        additionalData: { isReadOnly: prozess.isReadOnly },
        operation: value ? ProzessEventOperationTypes.CREATE : ProzessEventOperationTypes.DELETE,
        isValid: prozess.isRequired ? !!value?.length : true,
        prozessType: prozess.prozessType,
      } as ProzessInputData);
    },
    [
      isEditing,
      onChanged,
      prozess.changeEventType,
      prozess.eventCategory,
      prozess.eventType,
      prozess.isReadOnly,
      prozess.isRequired,
      prozess.prozessType,
      prozess.workflowId,
    ]
  );

  const reset = useCallback(
    () =>
      dispatch({
        type: KeyValueInfoTableActionType.RESET,
        payload: { value: undefined, isRequired, status: ProzessEventOperationTypes.DELETE },
      }),
    [isRequired]
  );

  const shouldEditProzessData = useCallback(
    // toggle this action if you need to create new prozess in edit mode.
    (isProzessEdited: boolean) =>
      dispatch({
        type: KeyValueInfoTableActionType.PROZESS_DATA_EDITED,
        payload: {
          value: undefined,
          isRequired,
          status: ProzessEventOperationTypes.EDIT,
          isProzessEdited,
        },
      }),
    [isRequired]
  );

  return { state, onProzessDataChanged, reset, save, shouldEditProzessData };
};
