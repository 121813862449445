import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { ConnectedDispatch, ConnectedState, OwnProps } from "./select-with-dialog.types";
import { useSelectWithDialogState } from "./select-with-dialog.hooks";
import useStyles from "./select-with-dialog.styles";

import { InputAdornment, TextField } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import SelectDialogContainer from "../../select-dialog/select-dialog.container";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { IProzessDataDto } from "../../../api/backend-api-v7";
import { EMPTY_DATA } from "../tierident/tierident.component";

const saveWhenRecordIsActive = (
  prevRecordId: string,
  recordId: string,
  save: (value: string | string[] | undefined) => void,
  value: string | string[] | undefined,
  isProzessEdited: boolean | undefined,
  isEditing: boolean
) => {
  const isRecordActive = prevRecordId === recordId;

  if (isEditing) {
    if (isRecordActive && isProzessEdited) {
      save(value);
    }
  } else {
    if (isRecordActive) {
      save(value);
    }
  }
};

interface Props extends OwnProps, ConnectedState, ConnectedDispatch {}

const SelectWithDialogComponent: FunctionComponent<Props> = props => {
  const {
    recordId,
    onChanged,
    prozess,
    shouldValidate,
    givenDataApplied,
    givenData,
    prevRecordId,
    editedValue,
    setIsValidationError,
    isManuallyEmpty,
    fullScreen,
    shouldCreateProzessEventForMultipleProzess,
    isEditing,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [shouldDisable, setShouldDisable] = useState(false);
  const [nextValue, setNextValue] = useState<IProzessDataDto | undefined>(undefined);
  const [displayedValues, setDisplayedValues] = useState<IProzessDataDto[]>([]);
  const [selectedValueFromGivenData, setSelectedValueFromGivenData] = useState<IProzessDataDto | undefined>(
    undefined
  );
  const [editedValueToDisplayInDialog, setEditedValueToDisplayInDialog] = useState<
    IProzessDataDto[] | undefined
  >(undefined);

  const { init, onProzessDataChanged, reset, save, state, shouldEditProzessData } = useSelectWithDialogState(
    prozess,
    onChanged,
    isEditing
  );

  /**
   * TextField onClick handler to open SelectDialogComponent.
   */
  const onOpen = () => {
    if (!shouldDisable && !prozess.isReadOnly) {
      setIsDialogOpen(true);
    }
  };

  /**
   * Determines how to dispay selected value in the TextField.
   * For IsMultipleSelectionAllowed configuration: "Count + ausgewählt".
   * Label or empty string for ather cases.
   * @return the {@link String}.
   */
  const revealDisplayValue = useCallback(() => {
    if (displayedValues.length) {
      if (prozess.isMultipleSelectionAllowed) {
        return `${displayedValues.length} ${t("COMMON.SELECTED")}`;
      } else {
        return displayedValues[0] ? displayedValues[0].label : "";
      }
    }
    return "";
  }, [displayedValues, prozess.isMultipleSelectionAllowed, t]);

  /**
   * Fires if PE with "ShouldCreateMultipleProzessEvents" prozess configuratin was created in edit mode.
   */
  const createProzessEventForMultipleProzess = useCallback(() => {
    if (shouldCreateProzessEventForMultipleProzess) {
      shouldEditProzessData(true);
    }
  }, [shouldCreateProzessEventForMultipleProzess, shouldEditProzessData]);

  /**
   * Applies selected values, modifies and creates PE.
   * @param value {@link IProzessDataDto}[].
   */
  const saveValues = useCallback(
    (values: IProzessDataDto[]): void => {
      let ids: string | string[] | undefined;
      if (values && values.length > 0) {
        if (prozess.isMultipleSelectionAllowed) {
          ids = values.map((value: IProzessDataDto) => value.id);
        } else {
          ids = [values[0].id];
        }
      } else {
        ids = [];
      }

      if (isEditing) {
        shouldEditProzessData(true);
      }
      onProzessDataChanged(ids);
      setDisplayedValues(values);
    },
    [
      isEditing,
      onProzessDataChanged,
      prozess.isMultipleSelectionAllowed,
      setDisplayedValues,
      shouldEditProzessData,
    ]
  );

  /**
   * Notifies the FunktionComponent to show validation error in edit mode.
   */
  const validateStateError = useCallback(() => {
    if (state.isError) {
      setIsValidationError(true);
    } else {
      setIsValidationError(false);
    }
  }, [setIsValidationError, state.isError]);

  const handleEditedValueToDisplay = useCallback(() => {
    let editedValueToDisplay: IProzessDataDto[] = [];

    // For multiple selection (isMultipleSelectionAllowed prozess configuration)
    if (Array.isArray(editedValue)) {
      editedValueToDisplay = editedValue.map(value => {
        const prozessData = prozess.data?.find(data => data.label === value);
        return prozessData!;
      });
    } else if (editedValue !== EMPTY_DATA) {
      const prozessData = prozess.data?.find(data => data.label === editedValue);
      editedValueToDisplay = [...editedValueToDisplay, prozessData!];
    }

    setDisplayedValues(editedValueToDisplay);
    setEditedValueToDisplayInDialog(editedValueToDisplay);
  }, [editedValue, prozess.data, setDisplayedValues]);

  const checkOnEditMode = useCallback(() => {
    if (isEditing && editedValue) {
      init();
      shouldEditProzessData(false);
      handleEditedValueToDisplay();

      if (prozess.shouldBeDisabledWhenReceivedFirstValue) {
        setShouldDisable(true);
      }
      onProzessDataChanged(editedValue);
    } else {
      shouldEditProzessData(false);
      setEditedValueToDisplayInDialog(undefined);
    }
  }, [
    editedValue,
    handleEditedValueToDisplay,
    init,
    isEditing,
    onProzessDataChanged,
    prozess.shouldBeDisabledWhenReceivedFirstValue,
    shouldEditProzessData,
  ]);

  const shouldInputBeDisabled = useCallback(() => {
    if (prozess.isReadOnly) {
      return true;
    }
    return prozess.shouldBeDisabledWhenReceivedFirstValue ? shouldDisable : false;
  }, [prozess.isReadOnly, prozess.shouldBeDisabledWhenReceivedFirstValue, shouldDisable]);

  const modifyWhenRecordIsActive = useCallback(() => {
    const isRecordActive = prevRecordId === recordId;
    if (isRecordActive) {
      if (prozess.shouldPreselectNextValue && nextValue) {
        onProzessDataChanged([nextValue.id]);
        setDisplayedValues([nextValue]);
      } else {
        reset();
        if (!prozess.shouldKeepValue) {
          setDisplayedValues([]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    onProzessDataChanged,
    prevRecordId,
    prozess.shouldKeepValue,
    prozess.shouldPreselectNextValue,
    recordId,
    reset,
    setDisplayedValues,
  ]);

  const checkOnGivenData = useCallback(() => {
    if (givenData) {
      if (givenData.data) {
        // Check extractDataFromProzessEvent function in prozess-events-saga.ts to know what data can be here.
        if (typeof givenData.data === "object") {
          // No additional information from the backend.
          setShouldDisable(false);
          reset();
          setDisplayedValues([]);
          setSelectedValueFromGivenData(undefined);
        } else {
          // Use additional information from backend.
          const prozessData = prozess.data?.find(d => d.id === givenData.data);
          setDisplayedValues(prozessData ? [prozessData] : []);
          setSelectedValueFromGivenData(prozessData);
          if (prozessData) {
            if (isEditing) {
              shouldEditProzessData(true);
            }
            onProzessDataChanged([givenData.data]);
          }
          if (prozess.shouldBeDisabledWhenReceivedFirstValue) {
            setShouldDisable(true);
          }
        }
      } else {
        // If prozess with shouldListenForChangesOnWorkflowId was removed, remove all data.
        reset();
        setDisplayedValues([]);
        setSelectedValueFromGivenData(undefined);
        setShouldDisable(false);
      }
      givenDataApplied(prozess.workflowId!);
    }
  }, [
    givenData,
    givenDataApplied,
    isEditing,
    onProzessDataChanged,
    prozess.data,
    prozess.workflowId,
    prozess.shouldBeDisabledWhenReceivedFirstValue,
    reset,
    setShouldDisable,
    shouldEditProzessData,
  ]);

  useEffect(() => {
    checkOnGivenData();
  }, [checkOnGivenData]);

  useEffect(() => {
    setDisplayedValues([]);
    init();
  }, [init, isEditing, setDisplayedValues, isManuallyEmpty]);

  useEffect(() => {
    checkOnEditMode();
  }, [checkOnEditMode]);

  useEffect(() => {
    createProzessEventForMultipleProzess();
  }, [createProzessEventForMultipleProzess]);

  useEffect(() => {
    validateStateError();
  }, [validateStateError]);

  useEffect(() => {
    modifyWhenRecordIsActive();
  }, [modifyWhenRecordIsActive]);

  useEffect(() => {
    saveWhenRecordIsActive(prevRecordId, recordId, save, state.value, state.isProzessEdited, isEditing);
    // Should listen only state changes for creating a prozess event.
    // It should update perfomance and prevent strange errors.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <div style={{ gridArea: prozess.position }} data-cy="SelectWithDialogComponent">
      <TextField
        label={prozess.label!}
        margin="normal"
        variant="outlined"
        fullWidth={true}
        onClick={onOpen}
        className={classnames(classes.formControl, {
          [classes.disabled]: shouldInputBeDisabled(),
        })}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <ArrowDropDownIcon />
            </InputAdornment>
          ),
        }}
        required={prozess.isRequired}
        InputLabelProps={{ shrink: true }}
        value={revealDisplayValue() || ""}
        error={shouldValidate && state.isError}
        disabled={shouldInputBeDisabled()}
      />
      <SelectDialogContainer
        prozess={prozess}
        fullScreen={fullScreen}
        recordId={recordId}
        saveValues={saveValues}
        editedValue={editedValueToDisplayInDialog}
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        nextValueToSelect={nextValue}
        setNextValue={setNextValue}
        selectedValueFromGivenData={selectedValueFromGivenData}
      />
    </div>
  );
};

export default SelectWithDialogComponent;
