import { Button, Tooltip } from "@mui/material";
import { FunctionComponent, useEffect, useState, useCallback } from "react";
import { ProzessDataDto } from "../../../api/backend-api-v7";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { findIconDefinition, IconName, library } from "@fortawesome/fontawesome-svg-core";
import { fas, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import { useGeschlechtState } from "./geschlecht.hooks";
import { ConnectedDispatch, ConnectedState, OwnProps } from "./geschlecht.types";
import useStyles from "./geschlecht.styles";

const createIcons = (data: ProzessDataDto[]) =>
  data.reduce((icons: { [id: string]: IconDefinition }, obj) => {
    icons[obj.id] = findIconDefinition({ prefix: "fas", iconName: obj.label as IconName });
    return icons;
  }, {});

const saveWhenRecordIsActive = (
  prevRecordId: string,
  recordId: string,
  save: (selectedGeschlecht: string | undefined) => void,
  geschlecht: string | undefined,
  isProzessEdited: boolean | undefined,
  isEditing: boolean
) => {
  const isRecordActive = prevRecordId === recordId;
  if (isEditing) {
    if (isRecordActive && isProzessEdited) {
      save(geschlecht);
    }
  } else {
    if (isRecordActive) {
      save(geschlecht);
    }
  }
};

interface Props extends OwnProps, ConnectedState, ConnectedDispatch {}

const GeschlechtComponent: FunctionComponent<Props> = props => {
  const {
    prozess,
    data,
    shouldValidate,
    onChanged,
    givenDataApplied,
    givenData,
    recordId,
    prevRecordId,
    editedValue,
    isManuallyEmpty,
    shouldCreateProzessEventForMultipleProzess,
    isEditing,
  } = props;
  const classes = useStyles();

  const [geschlechtIcons, setGeschlechtIcons] = useState<{ [id: string]: IconDefinition }>({});
  const { init, onProzessDataChanged, toggle, save, reset, state, shouldEditProzessData } =
    useGeschlechtState(prozess, onChanged, isEditing);

  const createProzessEventForMultipleProzess = useCallback(() => {
    // Fired if new prozess event for prozess which can create multiple prozess events was created in edit mode.
    if (shouldCreateProzessEventForMultipleProzess) {
      shouldEditProzessData(true);
    }
  }, [shouldCreateProzessEventForMultipleProzess, shouldEditProzessData]);

  const checkOnEditMode = useCallback(() => {
    if (isEditing && editedValue) {
      shouldEditProzessData(false);
      onProzessDataChanged(editedValue);
    } else {
      shouldEditProzessData(false);
      onProzessDataChanged("");
    }
  }, [editedValue, isEditing, onProzessDataChanged, shouldEditProzessData]);

  useEffect(() => {
    library.add(fas);
  }, []);

  useEffect(() => {
    createProzessEventForMultipleProzess();
  }, [createProzessEventForMultipleProzess]);

  useEffect(() => {
    setGeschlechtIcons(createIcons(data));
  }, [data]);

  useEffect(() => {
    saveWhenRecordIsActive(prevRecordId, recordId, save, state.geschlecht, state.isProzessEdited, isEditing);
    // Should listen only state changes for creating a prozess event.
    // It should update perfomance and prevent strange errors.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    init();
  }, [init, prozess, isManuallyEmpty]);

  useEffect(() => {
    reset();
  }, [recordId, reset]);

  useEffect(() => {
    if (givenData) {
      onProzessDataChanged(givenData.data);
      givenDataApplied(prozess.workflowId!);
    }
  }, [prozess.workflowId, givenData, onProzessDataChanged, givenDataApplied]);

  useEffect(() => {
    checkOnEditMode();
  }, [checkOnEditMode]);

  const isGewichtActive = (obj: ProzessDataDto) =>
    obj.label === state.geschlecht || obj.id === state.geschlecht;

  const onClickHandler = (id: string) => {
    shouldEditProzessData(true);
    toggle(id);
  };

  return (
    <div style={{ gridArea: prozess.position }} className={classes.root} data-cy="GeschlechtComponent">
      <span
        className={classnames(
          classes.touchButtonsParent,
          shouldValidate && state.isError ? classes.error : classes.valid
        )}
        data-cy={shouldValidate && state.isError ? "isError" : ""}
      >
        {data.map(
          (obj: ProzessDataDto) =>
            geschlechtIcons[obj.id] && (
              <Tooltip key={obj.value} title={obj.value || ""}>
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => onClickHandler(obj.id as string)}
                  className={classnames(classes.touchIconButton, {
                    [classes.activeButton]: isGewichtActive(obj),
                  })}
                >
                  <FontAwesomeIcon icon={geschlechtIcons[obj.id]} />
                </Button>
              </Tooltip>
            )
        )}
      </span>
    </div>
  );
};

export default GeschlechtComponent;
