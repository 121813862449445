import { FunctionComponent, useEffect, useCallback } from "react";
import useStyles from "./text-field.styles";
import classNames from "classnames";
import { Props } from "./text-field.types";
import { useTextFieldState } from "./text-field.hooks";
import { InputAdornment, TextField } from "@mui/material";

const saveWhenRecordIsActive = (
  prevRecordId: string,
  recordId: string,
  save: (value: number | string | undefined) => void,
  value: number | string | undefined,
  isProzessEdited: boolean | undefined,
  isEditing: boolean
) => {
  const isRecordActive = prevRecordId === recordId;

  if (isEditing) {
    if (isRecordActive && isProzessEdited) {
      save(value);
    }
  } else {
    if (isRecordActive) {
      save(value);
    }
  }
};

export enum FieldTypes {
  NUMBER = "number",
  STRING = "string",
}

const TextFieldComponent: FunctionComponent<Props> = props => {
  const {
    prozess,
    prevRecordId,
    recordId,
    onChanged,
    isManuallyEmpty,
    shouldValidate,
    editedValue,
    setIsValidationError,
    type,
    shouldCreateProzessEventForMultipleProzess,
    isEditing,
  } = props;
  const classes = useStyles();

  const { state, init, reset, save, onProzessDataChanged, shouldEditProzessData } = useTextFieldState(
    prozess,
    onChanged,
    isEditing,
    type
  );

  const createProzessEventForMultipleProzess = useCallback(() => {
    // Fired if new prozess event for prozess which can create multiple prozess events was created in edit mode.
    if (shouldCreateProzessEventForMultipleProzess) {
      shouldEditProzessData(true);
    }
  }, [shouldCreateProzessEventForMultipleProzess, shouldEditProzessData]);

  const checkOnEditMode = useCallback(() => {
    if (isEditing && editedValue) {
      shouldEditProzessData(false);
      onProzessDataChanged(editedValue);
    } else {
      reset();
    }
  }, [editedValue, isEditing, onProzessDataChanged, reset, shouldEditProzessData]);

  const validateStateError = useCallback(() => {
    // Say Funktion component, that prozess has a validation error in edit mode.
    if (state.isError) {
      setIsValidationError(true);
    } else {
      setIsValidationError(false);
    }
  }, [setIsValidationError, state.isError]);

  const evaluateClassName = useCallback(
    (optimalClassName: string, validClassName: string) =>
      state.isOptimal ? optimalClassName : state.isValid ? validClassName : "",
    [state.isOptimal, state.isValid]
  );

  const outlineBorderClassName = evaluateClassName(classes.optimalBorder, classes.validBorder);
  const outlineLabelClassName = evaluateClassName(classes.optimalLabel, classes.validLabel);

  useEffect(() => {
    init();
  }, [init, prozess]);

  useEffect(() => {
    reset();
  }, [recordId, reset, isManuallyEmpty]);

  useEffect(() => {
    saveWhenRecordIsActive(prevRecordId, recordId, save, state.value, state.isProzessEdited, isEditing);
  }, [isEditing, prevRecordId, recordId, save, state.isProzessEdited, state.value]);

  useEffect(() => {
    validateStateError();
  }, [validateStateError]);

  useEffect(() => {
    createProzessEventForMultipleProzess();
  }, [createProzessEventForMultipleProzess]);

  useEffect(() => {
    checkOnEditMode();
  }, [checkOnEditMode]);

  const onChangeHandler = (manualValue: string) => {
    if (isEditing) {
      shouldEditProzessData(true);
    }
    onProzessDataChanged(manualValue);
  };

  return (
    <div style={{ gridArea: prozess.position }} className={classes.root}>
      <TextField
        id={prozess.prozessType}
        required={prozess.isRequired}
        label={prozess.label!}
        variant="outlined"
        type={type}
        value={state.value ? state.value : ""}
        className={classNames(classes.textField, type === FieldTypes.NUMBER && outlineLabelClassName)}
        onChange={e => onChangeHandler(e.currentTarget.value)}
        InputProps={{
          classes: {
            notchedOutline: type === FieldTypes.NUMBER ? outlineBorderClassName : undefined,
          },
          endAdornment: <InputAdornment position="end">{prozess.valuePostfix}</InputAdornment>,
        }}
        InputLabelProps={{ shrink: true }}
        inputProps={{ min: 0 }}
        error={shouldValidate && state.isError}
      />
    </div>
  );
};

export default TextFieldComponent;
