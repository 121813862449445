import { FunctionComponent, useCallback, useEffect, useState, useRef } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ConfirmationModalWindow from "../../modal-windows/confirmation-dialog/confirmation-dialog.component";
import AppButton from "../../common/app-button/app-button";
import useStyles from "./gruppenerfassung.styles";
import classnames from "classnames";
import { useGruppenerfassungState } from "./gruppenerfassung.hooks";
import { Props, TransponderDto } from "./gruppenerfassung.types";
import { BluetoothDataFormat } from "../../../store/bluetooth/bluetooth-data-format";
import { checkInternetConnection } from "../../../utils/network-status";
import { findFerkelByQuery } from "../../../utils/ferkel.utils";

const saveWhenRecordIsActive = (
  prevRecordId: string,
  recordId: string,
  save: (transponder: TransponderDto) => void,
  transponder: TransponderDto
) => {
  const isRecordActive = prevRecordId === recordId;

  if (isRecordActive && transponder && !transponder.isProzessCreated) {
    save(transponder);
  }
};

const GruppenerfassungComponent: FunctionComponent<Props> = props => {
  const {
    prozess,
    onChanged,
    recordId,
    prevRecordId,
    transponder,
    shouldValidate,
    resetTransponderData,
    isManuallyEmpty,
    editedValue,
    saveDeletedTransponder,
    shouldCreateProzessEventForMultipleProzess,
    setShouldCreateProzessEventForMultipleProzess,
    setCountOfMultipleProzessEvents,
    isEditing,
  } = props;
  const classes = useStyles();
  const MIN_GRID_SIZE = 20;
  const [grid, setGrid] = useState(Array.apply(null, new Array(MIN_GRID_SIZE)));
  const [isDeletionDialogOpen, setIsDeletionDialogOpen] = useState(false);
  const [transponderForDelete, setTransponderForDelete] = useState<TransponderDto | undefined>(undefined);
  const { state, save, init, reset, onProzessDataChanged, deleteProzessData } = useGruppenerfassungState(
    prozess,
    onChanged,
    isEditing
  );
  const gridRef = useRef<any>(null);
  const newTransponder = state.transponders[state.transponders.length - 1];

  const updateGrid = useCallback(() => {
    // Add additional cell for the grid if count of values > MIN_GRID_SIZE.
    if (state.transponders.length > MIN_GRID_SIZE && grid.length < state.transponders.length) {
      setGrid(prev => [...prev, undefined]);
    }
  }, [grid, state.transponders.length]);

  const openDeleteDialog = (data: TransponderDto) => {
    setIsDeletionDialogOpen(true);
    setTransponderForDelete(data);
  };

  const closeDeleteDialog = () => {
    setIsDeletionDialogOpen(false);
    setTransponderForDelete(undefined);
  };

  const onDeleteTransponderHandler = (value: TransponderDto) => {
    if (isEditing && value.recordId) {
      const isOnline = checkInternetConnection();
      saveDeletedTransponder(value);
      if (isOnline) {
        // In edit mode value can be removed from the table only if internet connection is stable.
        deleteProzessData(value);
      }
    } else {
      // New values in edit mode and creation mode always can be deleted.
      deleteProzessData(value);
    }

    setIsDeletionDialogOpen(false);
  };

  const checkOnEditMode = useCallback(() => {
    if (isEditing && editedValue) {
      reset();
      // Map editing values and mark it as values which already has created prozess events (prozess events for these values already sent to server).
      const editingTransponders: TransponderDto[] = editedValue.map(
        (item: { value: string; recordId: string }) => ({
          transponder: item.value,
          metaData: undefined,
          recordId: item.recordId,
          isProzessCreated: true,
        })
      );
      onProzessDataChanged(editingTransponders);
    } else {
      reset();
      setShouldCreateProzessEventForMultipleProzess(false);
    }
  }, [editedValue, isEditing, onProzessDataChanged, reset, setShouldCreateProzessEventForMultipleProzess]);

  const isTransponderAlreadyUsed = useCallback(
    (bluetoothData: BluetoothDataFormat) =>
      state.transponders.some(item => item.transponder === bluetoothData.value),
    [state.transponders]
  );

  const isTransponderExist = useCallback(
    async (bluetoothData: BluetoothDataFormat) =>
      !!(await findFerkelByQuery("transponder", +bluetoothData.value)),
    []
  );

  const setNewValue = useCallback(async () => {
    if (transponder) {
      const isExist = await isTransponderExist(transponder);
      if (!isTransponderAlreadyUsed(transponder!) && isExist) {
        onProzessDataChanged({
          transponder: transponder.value,
          metaData: transponder,
          isProzessCreated: false,
        });
        resetTransponderData();
        if (isEditing && !shouldCreateProzessEventForMultipleProzess) {
          setShouldCreateProzessEventForMultipleProzess(true);
        }
      }
    }
  }, [
    isTransponderAlreadyUsed,
    isEditing,
    onProzessDataChanged,
    resetTransponderData,
    setShouldCreateProzessEventForMultipleProzess,
    shouldCreateProzessEventForMultipleProzess,
    transponder,
    isTransponderExist,
  ]);

  useEffect(() => {
    // Initialize gruppenerfassung state when component was rendered.
    init();
  }, [init, prozess]);

  useEffect(() => {
    // Reset gruppenerfassung state, prozess event counter and set default grid with MIN_GRID_SIZE after each succesfull saving data or Leeren button click.
    reset();
    setGrid(Array.apply(null, new Array(MIN_GRID_SIZE)));
    setCountOfMultipleProzessEvents(undefined);
  }, [recordId, isManuallyEmpty, reset, setCountOfMultipleProzessEvents]);

  useEffect(() => {
    updateGrid();
  }, [updateGrid]);

  useEffect(() => {
    saveWhenRecordIsActive(prevRecordId, recordId, save, newTransponder);
  }, [newTransponder, prevRecordId, recordId, save]);

  useEffect(() => {
    setNewValue();
  }, [setNewValue]);

  useEffect(() => {
    // Auto scrolling if transponder count more than 20.
    const { scrollLeft, scrollWidth, firstChild } = gridRef.current;
    if (scrollLeft !== scrollWidth) {
      gridRef.current.scrollTo(firstChild.clientWidth + scrollWidth, 0);
    }
  });

  useEffect(() => {
    checkOnEditMode();
  }, [checkOnEditMode]);

  useEffect(() => {
    // Update prozess event counter on each created or deleted value from gruppenerfassung state.
    setCountOfMultipleProzessEvents(state.transponders.length);
  }, [setCountOfMultipleProzessEvents, state.transponders.length]);

  return (
    <>
      <div
        data-cy="GruppenerfassungComponent"
        ref={gridRef}
        style={{ gridArea: prozess.position }}
        className={classnames(classes.container, shouldValidate && state.isError ? classes.error : "")}
      >
        {grid.map((item, index) => (
          <div
            key={index}
            className={classnames(
              classes.cell,
              state.transponders[index] && state.transponders[index].recordId ? classes.editingValue : ""
            )}
          >
            <span className={classes.index}>{`${index + 1}.`}</span>
            <span className={classes.transponder}>
              {state.transponders[index] ? state.transponders[index].transponder : ""}
            </span>
            {state.transponders[index] && (
              <AppButton
                className={classes.deleteButton}
                handler={() => openDeleteDialog(state.transponders[index])}
                type="button"
              >
                <DeleteForeverIcon />
              </AppButton>
            )}
          </div>
        ))}
      </div>
      {isDeletionDialogOpen && (
        <ConfirmationModalWindow
          closeModal={closeDeleteDialog}
          open={isDeletionDialogOpen}
          acceptHandler={() => onDeleteTransponderHandler(transponderForDelete!)}
          acceptButtonTitle="LÖSCHEN"
        >
          Möchten Sie diesen Datensatz unwiderruflich löschen?
        </ConfirmationModalWindow>
      )}
    </>
  );
};

export default GruppenerfassungComponent;
